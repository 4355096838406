import React, { useEffect, useState } from 'react';
import './checkout.css';
import Doggif from './../../images/Section2/Dog paw.gif';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useQuery } from 'react-query';
import Spinner from '../../components/Spinner/Spinner';


const CheckoutPoint = () => {
    const [checkOutData, setCheckOutData] = useState([]);
    const location = useLocation();
    const [selectedDistrict, setSelectedDistrict] = useState(null);
    const [districts, setDistricts] = useState([]);
    const [point, SetPoint] = useState('');
    const [spinner, setSpinner] = useState(true)
    const [spinner2, setSpinner2] = useState(false)
    const [oldAddress, setOldAddress] = useState('');
    const [oldName, setOldName] = useState('');
    const [oldPhone, setOldPhone] = useState('');
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        phoneNumber: '',
        name: '',
        address: '',
    });
    const pathnameParams = location.pathname.split('/').filter(Boolean);
    let quantity;

    useEffect(() => {
        const fetchData = async () => {
            try {
                const token = localStorage.getItem('authToken');

                if (pathnameParams.length > 1) {
                    const response = await fetch(`${API_BASE_URL}/orders/checkOut/${pathnameParams[1]}`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}`,
                        },
                        body: JSON.stringify({
                            quantity: quantity !== null ? quantity : 1,
                        }),
                    });
                    setSpinner(false)
                    // console.log("In has param " + pathnameParams[1]);
                    
                    if (response.ok) {
                        const responseData = await response.json();
                        setCheckOutData(responseData.data.items);
                        SetPoint(responseData.data.items.total_in_point)
                        // console.log(responseData.data['districts'])
                        setDistricts(responseData.data['districts'])
                        // console.log(responseData.data.items);
                    } else {
                        // console.error('Error fetching orders:', response.statusText);

                    }
                }
                return
            } catch (error) {
                console.error('Error fetching data:', error);
                return
            }
        };

        fetchData();
    }, []);


    const fetchAddress = async () => {
        try {
          const authToken = localStorage.getItem('authToken');
          if (oldAddress && oldName && oldPhone) {
            // If oldAddress, oldName, and oldPhone are already set, use them
            setFormData({
              ...formData,
              address: oldAddress,
              name: oldName,
              phoneNumber: oldPhone
            });
          } else {
            const response = await axios.get(`${API_BASE_URL}/orders/same_address`, {
              headers: {
                'Authorization': `Bearer ${authToken}`,
                'Content-Type': 'application/json',
              },
            });
    
            // console.log('Full Response:', response);
    
            const fetchedData = response.data.data;
            // console.log('Fetched Data:', fetchedData);
            const fetchedName = fetchedData.username;
            const fetchedAddress = fetchedData.location; 
            const fetchedPhone = fetchedData.phone;
    
    
            setOldName(fetchedName);
            setOldAddress(fetchedAddress);
            // console.log('Fetched Phone:', fetchedPhone);
            setOldPhone(fetchedPhone);
    
            setFormData({
              ...formData,
              name: fetchedName,
              address: fetchedAddress,
              phoneNumber: fetchedPhone,
            });
    
          }
        } catch (error) {
          console.error('Error fetching address data:', error);
        }
      };
      useEffect(() => {
        fetchAddress();
      }, []);

    const [errors, setErrors] = useState({
        phoneNumber: '',
        name: '',
        address: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value,
        });

        setErrors({
            ...errors,
            [e.target.name]: '',
        });
    };

    const handleOrderNow = async () => {
        try {
            // Validation
            let hasErrors = false;
            const newErrors = { phoneNumber: '', name: '', address: '' };

            if (!formData || !formData.phoneNumber.trim()) {
                newErrors.phoneNumber = 'Phone number is required';
                hasErrors = true;
            }

            if (!formData || !formData.name.trim()) {
                newErrors.name = 'Name is required';
                hasErrors = true;
            }

            if (!formData || !formData.address.trim()) {
                newErrors.address = 'Address is required';
                hasErrors = true;
            }

            if (hasErrors) {
                setErrors(newErrors);
            } else {
                let data;
                if (pathnameParams.length > 1) {
                    // console.log("path " + pathnameParams)
                    data = {
                        name: formData.name.trim(),
                        location: formData.address.trim(),
                        phone_number: formData.phoneNumber.trim(),
                        quantity: new URLSearchParams(location.search).get("quantity") || 1,
                        districts: selectedDistrict
                    };
                } else {
                    data = {
                        name: formData.name.trim(),
                        location: formData.address.trim(),
                        phone_number: formData.phoneNumber.trim(),
                        districts:selectedDistrict
                    };
                }

                const codeFromSession = sessionStorage.getItem('userCode');

                if (codeFromSession) {
                    data.code = codeFromSession;

                    // Clear the session after accessing the code
                    sessionStorage.removeItem('userCode');
                }

                // console.log('Order placed:', codeFromSession ? { ...data, code: codeFromSession } : data);

                const token = localStorage.getItem('authToken');
                const endpointURL = pathnameParams[1] && pathnameParams[1].length > 0
                    ? `${API_BASE_URL}/orders/${pathnameParams[1]}/buy_now`
                    : `${API_BASE_URL}/orders/create`;

                // console.log(endpointURL + " data " + JSON.stringify(data) + "slug " + pathnameParams[1]);
                setSpinner2(true)
                const response = await fetch(endpointURL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    },
                    body: JSON.stringify(data),
                });
                setSpinner(false)
                setSpinner2(true)

                if (response.status === 200) {
                    // setShowToast(true);
                    toast.success('Your Order has been Sent Successfully');
                    navigate('/invoice')
                } else {
                    // setShowToast(true);
                    toast.error('Error in Your Order, Please Try Again');
                }

                // console.log('Order placed:', codeFromSession ? { ...data, code: codeFromSession } : data);
            }
        } catch (error) {
            // console.error('Error handling order:', error);
        }
    };

    const handleDistrictChange = (e) => {
    const selectedDistrictId = parseInt(e.target.value, 10);
    const district = districts.find((d) => d.id === selectedDistrictId);
    setSelectedDistrict(district.name);
  };



    return (
        <>
            <div className='allOfCheckOut'>
                {/* <Banner title="Point Check Out" /> */}

                <div className='contentOfCheckout'>
                    <h1>Confirmation Order</h1>

                    {spinner ? (
                        <Spinner />
                    ) : (
                        <>
                            <div className='allProductInCheckoutPoint'>
                                {checkOutData.length > 1 ? (
                                    checkOutData.map((item, index) => (
                                        <div key={index} className="MoreScroll">
                                            <img src={item.image} alt={item.name} className="More2" />
                                            <div className="ContainerOfDetails">
                                                <h2>{item.name}</h2>
                                                <p>{item.total_in_point}</p>
                                            </div>
                                        </div>
                                    ))
                                ) : (
                                    <div className="MoreScroll">
                                        <img src={checkOutData.image} alt={checkOutData.name} className="More2" />
                                        <div className="ContainerOfDetails">
                                            <h2>{checkOutData.name}</h2>
                                            <p>{checkOutData.total_in_point}</p>
                                        </div>
                                    </div>
                                )}
                            </div>

                            {checkOutData.length > 0 || checkOutData.id ? (
                                <div className='allthesubone'>
                                    <div className='checkoutInPut'>
                                        <input
                                            type="tel"
                                            name="phoneNumber"
                                            value={formData.phoneNumber || oldPhone}
                                            onChange={handleChange}
                                            placeholder="Enter your phone number"
                                        />
                                        {errors.phoneNumber && <p className='error-message'>{errors.phoneNumber}</p>}
                                        <input
                                            type="text"
                                            name="name"
                                            value={formData.name || oldName}
                                            onChange={handleChange}
                                            placeholder="Enter your name"
                                        />
                                        {errors.name && <p className='error-message'>{errors.name}</p>}

                                        <div className='divOffSelectDistrict'>
                                            <label className='selectDistrict'>Select District:</label>
                                            <select onChange={handleDistrictChange} className='SelectDistrictDropDown'>
                                            <option value="" disabled selected>Select a District</option>

                                            {districts && districts.map((district) => {
                                                const deliveryFeeNote = `${district.delivery_fees}$`;
                                                return (
                                                <option key={district.id} value={district.id} className='optionInSelectCity'>
                                                    {district.name} {deliveryFeeNote}
                                                </option>
                                                );
                                            })}
                                            </select>
                                        </div>

                                        <div className="input-container">
                                            <textarea
                                                type="text"
                                                name="address"
                                                value={formData.address || oldAddress}
                                                onChange={handleChange}
                                                placeholder="Enter your address"
                                                className="address-input"
                                            />
                                            <button
                                                type="button"
                                                onClick={fetchAddress}
                                                className={`save-button ${oldAddress ? 'has-old-address' : ''}`}
                                            >
                                                {oldAddress ? 'Restore Address' : 'Save Address'}
                                            </button>
                                        </div>

                                        {errors.address && <p className='error-message'>{errors.address}</p>}
                                    </div>
                                    <div className='btnofcheckout'>
                                        <button className='checkoutbtn' onClick={handleOrderNow}>
                                            {spinner2 ? 'Loading...' : 'Order Now'}
                                        </button>
                                    </div>
                                </div>
                            ) : (
                                <p>No Items in Your Cart</p>
                            )}
                        </>
                    )}
                </div>
                <div>
                    <img src={Doggif} alt='' className='doggif'></img>
                </div>
            </div>
        </>

    );
};

export default CheckoutPoint;

