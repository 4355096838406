// LoadingComponent.jsx
import React from 'react';
import './Loading.css';
import logo from './../../images/Header/logo.png';
import gifload from '../../images/Header/wired-outline-1193-pets.gif'
import gifload2 from '../../images/Header/Ellipsis-0.9s-470px.gif'


const LoadingComponent = () => {
  return (
    <div className="loader-container">
      <div className="loader">
        <img src={gifload}></img>
        <div className='logoWithdots'>
          <img src={logo} alt="Logo" className='logoLoading' />
          <img src={gifload2} alt="Logo" className='logoLoading' />
        </div>
      </div>
    </div>
  );
};

export default LoadingComponent;