import React, { useEffect, useState } from 'react';
import './banner.css';
import {  useNavigate } from 'react-router-dom';


const Banner = ({ title }) => {
    const navigate = useNavigate();
    const [searchText , setSearchText] = useState('');

    const handleShop = () => {
        navigate(`/Product?searchText=${searchText}#allProducts`);
        const allProductElement = document.getElementById('allProduct')
        if (allProductElement) {
            allProductElement.scrollIntoView({ behavior: 'smooth' });
        };
    }

    return (
        <div className='banner'>
            <h1>{title}</h1>
            <button className='Shop' onClick={handleShop}>
                Shop Now
            </button>
        </div>
    );
};

export default Banner;