import React, { useState } from 'react';
import './Product.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import seller from './../../images/Best Seller/seller.png';
import axios from 'axios';
import { API_BASE_URL } from '../../config.js';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

const Product = ({ products, pageType }) => {
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(true);
  const [heartColors, setHeartColors] = useState({});


  const handleAddToCart = async (e, slug) => {
    e.preventDefault();
    const data = {
      quantity: 1,
    };

    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setShowToast(true);
        toast.error('Please log in to add items to your cart.');
        return;
      }

      const response = await axios.post(`${API_BASE_URL}/addToCart/${slug}`, data, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.data.message) {
        setShowToast(true);
        toast.success(response.data.message);
        // eslint-disable-next-line no-undef
        fbq('track', 'AddToCart')
      } else {
        setShowToast(true);
        toast.error('An error occurred. Please try again.');
      }
    } catch (error) {
    }
  };



  const handleWishlistItem = async (e, slug) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('authToken');
      const response = await axios.get(`${API_BASE_URL}/wishlist/${slug}/add`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.data.message) {
        setShowToast(true);
        toast.success(response.data.message);
        setHeartColors((prevColors) => ({ ...prevColors, [slug]: '#f6a7c6' }));
      } else {
        setShowToast(true);
        toast.error(response.data);
      }
      return
    } catch (error) {
      setShowToast(true);
      toast.error('An error occurred. Please try again.');
    }
  };
  
  const handleClickProduct = (e, slug, isOutOfStock) => {
    if (!isOutOfStock) {
      navigate(`/MoreDetails/${slug}#MoreDetails`);
    }
    e.preventDefault();
    return;
  };

  return (
    <div className='ContainerOfcat'>
      <div className={`ContainerOfcat2 ${pageType === 'Holidays' ? 'otherPageStyle' : 'Productitems'}`}>
        {Array.isArray(products) && products.map((item) => (
          <div key={item.id} className='bestSellerItem'>
            <div className='divCacher'>
              {item.stock == false && (
                <h2 className='stock_status'>{item.stock_status}</h2>
              )}
              {item.image ? (
                <img src={item.image} alt={item.name} className={`imgseller ${item.stock == false ? 'outOfStock' : ''}`} onClick={(e) => handleClickProduct(e, item.slug, item.stock == false)} />
              ) : (
                <img src={seller} alt={item.name} className={`imgseller ${item.stock == false ? 'outOfStock' : ''}`} />
              )}
              <div className={`overlay${item.stock == false ? 'overlayOutOfStock' : ''}`}>
                <FontAwesomeIcon icon={faHeart} className='icon12' onClick={(e) => handleWishlistItem(e, item.slug)} style={{ color: heartColors[item.slug] || 'white' }} />
                <FontAwesomeIcon icon={faShoppingCart} className='icon12' onClick={(e) => handleAddToCart(e, item.slug)} />
              </div>
            </div>
            <div className='ContainerOfdescriptionAndPrice' onClick={(e) => handleClickProduct(e, item.slug, item.stock == false)}>
              <h2>{item.name}</h2>
             {item.has_offer === true ? (
                <div className='priceContainer'>
                <p className='regularPricee'>{parseFloat(item.price.split('.')[1]) === 0 ? parseFloat(item.price).toFixed(0) :  parseFloat(item.price).toFixed(2)} $</p>
                <p className='offerPrice'>{parseFloat(item.raw_price.split('.')[1]) === 0 ? parseFloat(item.raw_price).toFixed(0) :  parseFloat(item.raw_price).toFixed(2)}$</p>
                </div>
              ) : (
                <p className='regularPrice'>{parseFloat(item.price.split('.')[1]) === 0 ? parseFloat(item.price).toFixed(0) :  parseFloat(item.price).toFixed(2)} $</p>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Product;
