import React, { useEffect, useState } from 'react';
import pet2 from "./../../images/Categories/pet2.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './../BestSeller/BestSeller.css';
import { API_BASE_URL } from '../../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function AllProduct() {
  const [heartColors, setHeartColors] = useState({});
  const [showToast, setShowToast] = useState(true);
  const navigate = useNavigate();
  const [searchText , setSearchText] = useState('')
  const [disable , setDisable] = useState(false)
  const [bestSellerData, setBestSellerData] = useState([]);

  const handelSubmit = () => {
    navigate(`/product?search_text=${searchText}`);
  };

  const handleButtonClick = (e, slug, isOutOfStock) => {
    if (!isOutOfStock) {
      navigate(`/MoreDetails/${slug}#MoreDetails`);
    }

    e.preventDefault();
    return;
  };


  const fetchDataProduct = async () => {
    // console.log("fetchdata product")
    try {
      const response = await axios.get(`${API_BASE_URL}/products?per_page=12`);
      setBestSellerData(response.data.data);
      //localStorage.setItem('AllProduct', JSON.stringify(response.data.data));
      // console.log("datafetched and set in local stoage")
      return
    } catch (error) {
      // console.error('Error fetching data:', error);
      return
    }
  };


  useEffect(() => {
   // const storedData = localStorage.getItem('AllProduct');
    //if (storedData) {
      //console.log("data found")
      //setBestSellerData(JSON.parse(storedData));
   // } else {
    //  console.log("use effect executed")
      fetchDataProduct()
    //}
    // if(!bestSellerData || bestSellerData.length === 0){
    //   fetchDataProduct()
    // }
  }, []);



  const handleAddToCart = async (e, slug) => {
    e.preventDefault();
    // window.fbq('track', 'AddToCart');
    if (disable) {
      return;
    }
    setDisable(true);
    const data = {
      quantity: 1,
    };

    try {
      const token = localStorage.getItem('authToken');

      if (!token) {
        setShowToast(true);
        setDisable(false)
        toast.error('Please log in to add items to your cart.');
        return;
      }
      const response = await axios.post(`${API_BASE_URL}/addToCart/${slug}`, data, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.data.message) {
        setShowToast(true);
        toast.success(response.data.message);
      } else {
        setShowToast(true);
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      setDisable(false);
    }
  };


  const handleWishlistItem = async (e, slug) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('authToken');
      const response = await axios.get(`${API_BASE_URL}/wishlist/${slug}/add`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.data.message) {
        setShowToast(true);
        toast.success(response.data.message);

        // Update the color for the specific product
        setHeartColors((prevColors) => ({ ...prevColors, [slug]: '#f6a7c6' }));
      } else {
        // // Check for specific error conditions in your response data
        // if (response.data.error === 'some_error_condition') {
        //   setShowToast(true);
        //   toast.error(response.data);
        //   console.error('Error adding to wishlist. Response data:', response.data);
        // } else {
        //   // Handle other cases if needed
        // }
      }
    } catch (error) {
      setShowToast(true);
      toast.error(error.message);
      // console.error('Error adding to wishlist:', error.message);
    }
  };
  return (
    <div className='ContainerOfAllSeller'>
      <div className="divider">
        <div className="divider-line"></div>
        <h1 className='CategoriesTitle'>All Products</h1>
        <div className="divider-line"></div>
      </div>
      <img src={pet2} alt="" className='reverse3'></img>
      <div className='bestSellerItems' >
        {bestSellerData && bestSellerData.slice(0, 12).map((item, index) => (
          <div key={item.id} className='bestSellerItem'>
            <div className='divCacher'>
              {item.stock == false && (
                <h2 className='stock_status'>{item.stock_status}</h2>
              )}
              <img src={item.image} alt={item.name} className={`imgseller ${item.stock == false ? 'outOfStock' : ''}`}  onClick={(e) => handleButtonClick(e, item.slug, item.stock == false)}/>
              <div className={`overlay${item.stock == false ? 'overlayOutOfStock' : ''}`}>
                <FontAwesomeIcon icon={faHeart} className='icon12' onClick={(e) => handleWishlistItem(e, item.slug)} style={{ color: heartColors[item.slug] || 'white' }} />
                <FontAwesomeIcon icon={faShoppingCart} className='icon12' onClick={(e) => handleAddToCart(e, item.slug)} id={item.slug} disabled={disable}/>
              </div>
            </div>
            <div className='ContainerOfdescriptionAndPrice' onClick={(e) => handleButtonClick(e, item.slug, item.stock == false)}>
              <h2>{item.name}</h2>
              {item.has_offer === true ? (
                <div className='priceContainer'>
                  <p className='regularPricee'>{parseFloat(item.price.split('.')[1]) === 0 ? parseFloat(item.price).toFixed(0) :  parseFloat(item.price).toFixed(2)} $</p>
                  <p className='offerPrice'>{parseFloat(item.raw_price.split('.')[1]) === 0 ? parseFloat(item.raw_price).toFixed(0) :  parseFloat(item.raw_price).toFixed(2)}$</p>
                </div>
              ) : (
                <p className='regularPrice'>{parseFloat(item.price.split('.')[1]) === 0 ? parseFloat(item.price).toFixed(0) :  parseFloat(item.price).toFixed(2)} $</p>
                )}
            </div>
          </div>
        ))}
      </div>
      <div className='btnSeller'>
        <button onClick={handelSubmit}>View More</button>
      </div>
    </div>
  );
}

export default AllProduct;
