import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Home from './Pages/Home';
import Footer from './components/Footer/footer';
import NewUser from './User/NewUser';
import CatProduct from './Pages/Products/CatProduct';
import DogProduct from './Pages/Products/DogProduct';
import AllProduct from './Section/AllProduct/AllProduct';
import Contactus from './Pages/ContactUs/Contactus';
import Checkout from './Pages/CheckoutPage/Checkout';
import Wishlist from './Pages/WishList/Wishlist';
import Cart from './Pages/Cart/Cart';
import Holidays from './Pages/Holidays/Holidays';
import Brands from './Pages/Brands/Brands';
import MoreDetails from './Pages/MoreDetails/MoreDetails';
import AllProductp from './Pages/Products/AllProductp';
import Points from './Pages/points/Points';
import HeaderBanner from './components/Header/HeaderBanner';
import Terms from './Pages/terms and Conditions/Terms';
import Condition from './Pages/terms and Conditions/Condition';
import Innvoice from './Pages/innvoice/innvoice';
import AboutPage from './Pages/About-us/AboutPage';
import LoadingComponent from './components/Loading/Loading';
import PointsProductDetails from './Pages/points/PointsProductDetails';
import { useIdleTimer } from 'react-idle-timer'
import { useAuth } from './User/AuthContext/AuthContext';
import CheckoutPoint from './Pages/CheckoutPage/CheckoutPoint';
import NotFoundPage from './Section/NotFoundPage';
// import Facebook from './components/Facebook/facebook';
// import MetaPixel from './utils/meta/metaPixel';

const timeout = 480_000
const promptBeforeIdle = 32_000

function App() {

  const [state, setState] = useState('Active')
  const [remaining, setRemaining] = useState(timeout)
  const [open, setOpen] = useState(false)
  const {logout} = useAuth();

  const onIdle = () => {
    setState('Idle')
    setOpen(false)
  }

  const onActive = () => {
    setState('Active')
    setOpen(false)
  }

  const onPrompt = () => {
    setState('Prompted')
    setOpen(true)
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500
  })

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if(token)
    {
      const interval = setInterval(() => {
        setRemaining(Math.ceil(getRemainingTime() / 1000))
      }, 500)
  
      return () => {
        clearInterval(interval)
      }
    }
  })

  const handleStillHere = () => {
    activate()
  }

  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0)
  const seconds = timeTillPrompt > 1 ? 'seconds' : 'second'

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if(token)
    {
      if (timeTillPrompt === 0) {
        console.log("session closed")
        const confirmResult = window.confirm("Session expired. Click OK to reload the page.");
        if (confirmResult) {
          logout();
          window.location.reload();
        }
      }
    }
  }, [timeTillPrompt, logout]);       

  return (
    <Router>
      <HeaderBanner/>
      <Routes>
        <Route  path="/" element={<Home />} />
        <Route exact path="/registration" element={<NewUser/>} />
        <Route exact path="/CatProducts/:categoryId" element={<CatProduct/>} />
        <Route exact path="/CatProducts/:categoryId/:categorySlug/:categorySubSlug" element={<CatProduct/>} />
        <Route exact path="/CatProducts/:categoryId/:categorySlug" element={<CatProduct/>} />
        <Route exact path="/DogProducts/:categoryId" element={<DogProduct/>} />
        <Route exact path="/DogProducts/:categoryId/:categorySlug/:categorySubSlug" element={<DogProduct/>} />
        <Route exact path="/DogProducts/:categoryId/:categorySlug" element={<DogProduct/>} />
        <Route exact path="/AllProduct" element={<AllProduct/>} />
        <Route exact path="/Product/categories/:id" element={<AllProductp/>} />
        <Route exact path="/product" element={<AllProductp/>} />
        <Route exact path="/contact" element={<Contactus/>} />
        <Route exact path="/about" element={<AboutPage/>} />
        <Route exact path="/checkOut/:slug?" element={<Checkout/>} />
        <Route exact path="/CheckoutPoint/:slug?" element={<CheckoutPoint/>} />
        <Route exact path="/Wishlist" element={<Wishlist/>} />
        <Route exact path="/Cart" element={<Cart/>} />
        <Route exact path="/TermsAndCondition"  element={<Terms/>}/>
        <Route exact path="/PrivacyPolicies"  element={<Condition/>}/>
        <Route exact path="/Holidays" element={<Holidays/>} />
        <Route exact path="/Brands" element={<Brands/>} />
        <Route exact path="/award" element={<Points/>} />
        <Route exact path="/invoice" element={<Innvoice/>} />
        <Route exact path="/PointsProductDetails/:slug" element={<PointsProductDetails/>} />
        <Route exact path="/MoreDetails/:slug" element={<MoreDetails/>} />
        <Route path='/loading' element={<LoadingComponent/>}/>
        <Route  path="*" element={<NotFoundPage/>} />
      </Routes>
      {/* <p>{timeTillPrompt}</p> */}
      <Footer/>
      {/* <Facebook/> */}
    </Router>

  );
}

export default App;
