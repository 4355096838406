import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import { API_BASE_URL } from '../../config';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import Banner from '../../Section/banner/banner';

const PointsProductDetails = () => {

    const [showDescription, setShowDescription] = useState(true);
    const [showIngredients, setShowIngredients] = useState(true);
    const navigate = useNavigate();


    const toggleDescription = () => {
        setShowDescription((prevShow) => !prevShow);
    };

    const toggleIngredients = () => {
        setShowIngredients((prevShow) => !prevShow);
    };

    const handleBuyNow = (e, slug) => {
        e.preventDefault();
        navigate(`/CheckoutPoint/${slug}`);
    };

    const { data: detailsData} = useQuery('productDetails', async () => {
        try {
            const slug = window.location.pathname.split('/').pop();
            const response = await axios.get(`${API_BASE_URL}/products/${slug}`);
            return response.data.data;
        } catch (error) {
            throw error;
        }
    });


    return (
        <div className="ALLMore">
            {/* <Banner title='Product Point Details' /> */}
            <img src={detailsData?.image} alt={detailsData?.name} className="imgMore" />
            <div className='widthOfMore'>
                <div className="MoreTitleName">
                    <div className='priceInMoreDetails'>
                        <h1>{detailsData?.name}</h1>
                    </div>
                    <div className='priceInMoreDetails'>
                        <h4>POINTS: <span className='priceInSpan'>{detailsData?.points}</span></h4>
                    </div>
                </div>
                <div className="MoreDetails">
                    <div className="MoreDetails1">
                        <h1>Details</h1>
                    </div>
                    <div onClick={toggleDescription} className='minusPlus'>
                        <FontAwesomeIcon icon={showDescription ? faMinus : faPlus} className='minusPluss' />
                    </div>
                </div>
                {showDescription && (
                    <div className="Description">
                        <p dangerouslySetInnerHTML={{ __html: detailsData?.description }}></p>
                    </div>
                )}
                <div className="MoreIngredient">
                    <div className="MoreIngredient1">
                        <h1>Ingredients & Analysis</h1>
                    </div>
                    <div onClick={toggleIngredients} className='minusPlus'>
                        <FontAwesomeIcon icon={showIngredients ? faPlus : faMinus} className='minusPluss' />
                    </div>
                </div>
                {showIngredients && (
                    <div className="Ingredients">
                        <p dangerouslySetInnerHTML={{ __html: detailsData?.ingredients }}></p>
                    </div>
                )}
                <div className='btnForAllmore'>
                    <button className='ByBtn' onClick={(e) => handleBuyNow(e, detailsData.slug)}>Buy Now</button>
                </div>
            </div>
        </div>
    );
}

export default PointsProductDetails;
