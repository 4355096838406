import { createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isLoggedIn, setLoggedIn] = useState(false);
  // const navigate = useNavigate()

  useEffect(() => {
    const token = localStorage.getItem('authToken');
    if (token) {
      setLoggedIn(true);

    }
  }, []);

  const login = () => {
    setLoggedIn(true);
    window.location.reload()

  };

  const logout = () => {
    localStorage.removeItem('authToken');
    setLoggedIn(false);
    // navigate('/')
    window.location.reload()
  };

  return (
    <AuthContext.Provider value={{ isLoggedIn, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};

