import React, { useEffect, useRef, useState } from 'react';
import './innvoice.css';
import axios from 'axios';
import { API_BASE_URL } from '../../config';
import Login from "../../User/Login/Login";
import Spinner from '../../components/Spinner/Spinner';
import Banner from '../../Section/banner/banner';

const Innvoice = () => {
    const [data, setData] = useState([]);
    const [userStatuts, setUserStatus] = useState(false);
    const [spinner, setSpinner] = useState(true);
    const token = localStorage.getItem('authToken');
    const fetchData = async () => {
        try {
            if (token) {
                const response = await axios.get(`${API_BASE_URL}/orders`, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                setSpinner(false)
                setData(response.data.data);
                setUserStatus(response.status);
                return;
            }
            setSpinner(false)
        } catch (error) {
            setSpinner(false)
            return;
        }
    };

    useEffect(() => {
        if (data.length === 0) {
            fetchData();
        }
    }, [token]);


    const statusStyles = {
        'REFUNDED': 'status-refund',
        'CONFIRMED': 'status-confirm',
        'DELIVERED': 'status-delivered',

    };
    const innvoiceRef = useRef(null);
    useEffect(() => {
        if (window.location.search.includes('Header')) {
            if (innvoiceRef.current) {
                innvoiceRef.current.scrollIntoView({ behavior: 'smooth' });
            }
        }
    }, []);

    return (
        <div className='INVOICE' ref={innvoiceRef}>

            {
                spinner ? (
                    <Spinner />
                ) : (
                    userStatuts === 200 ? (
                        data.length > 0 ? (
                            <div className="innvoice">
                                <div className='containerOfInvoice'>
                                    <table className='invoiceTable'>
                                        <thead>
                                            <tr className='themainTitle'>
                                                <th>Items</th>
                                                <th>Total</th>
                                                <th>Payment Status</th>
                                                <th>Order Status</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data.map((singleOrder, index) => (
                                                singleOrder.items.map((item, subIndex) => (
                                                    <tr key={subIndex}>
                                                        <td className='des'><h3>{item.description}</h3></td>
                                                        <td><p className='TotalPrice111'>{singleOrder.by_points == 0 ? (item.total.split('.')[1] === '00'? item.total.split('.')[0] : item.total) : item.point_price + " points"}</p></td>
                                                        <td><p className='paidUnpaid'>{singleOrder.payment_status}</p></td>
                                                        <td className={`ggg ${statusStyles[singleOrder.order_status]}`} dangerouslySetInnerHTML={{ __html: singleOrder.order_status }} />
                                                    </tr>
                                                ))
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        ) : (
                            <div className='allwishlist'>
                                <h1>No Orders</h1>
                            </div>
                        )
                    ) : (
                        <div className='allwishlist'>
                            <h1>You Should Sign In To Show Your Orders</h1>
                            <div>
                                {<Login />}
                            </div>
                        </div>
                    )
                )
            }
        </div>
    );
};

export default Innvoice;
