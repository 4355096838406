import React, { useEffect, useRef, useState } from 'react';
import './Cart.css';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Quantity from '../../components/Quantity/Quantity';
import seller from './../../images/Best Seller/seller.png';
import { API_BASE_URL } from '../../config.js';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../../components/Spinner/Spinner.js';
import Login from '../../User/Login/Login';
import Banner from '../../Section/banner/banner.js';


const Cart = () => {
  const [productQuantities, setProductQuantities] = useState({});
  const [totalPrice, setTotalPrice] = useState(0);
  const [cart, setCart] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const [spinnerQty, setSpinnerQty] = useState(false);
  const [userStatus, setUserStatus] = useState('')
  const navigate = useNavigate();

  const removeProduct = async (e, product_id) => {
    e.preventDefault();
    const thisClicked = e.currentTarget;
    thisClicked.innerText = 'Removing';
  
    try {
      const token = localStorage.getItem('authToken');
      const response = await fetch(`${API_BASE_URL}/cart/removeItem`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ product_id: product_id }),
      });
  
      setSpinner(false);
  
      if (response.status === 200) {
        // console.log("AWA");
        fetchData(); 
        toast.success("Delete Succssuful!", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          className: 'custom-toast',
        });
      } else if (response.status === 404) {
        toast.error("Error Notification !", {
          position: toast.POSITION.BOTTOM_RIGHT,
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          className: 'custom-toast1',
        });
      }
    } catch (error) {
      toast.error("Error Notification !", {
        position: toast.POSITION.BOTTOM_RIGHT,
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        className: 'custom-toast1',
      });
    }
  };
  

  const token = localStorage.getItem('authToken');

  const fetchData = async () => {
    if(token){
      try {
      const response = await axios.get(`${API_BASE_URL}/my_cart`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
        setSpinner(false)
        setUserStatus(response.status)
      if (response.status === 200) {
        setTotalPrice(response.data.data.total)
        setCart(response.data.data.items)
      } else if (response.status === 401) {

      }
      return
    } catch (error) {
      setSpinner(false)
      return;
    }}
    else{
      // console.log('hii')
    }
  };

  // console.log("cart page", cart)

  useEffect(() => {
          fetchData();
  }, []); 

  const handleOrder = () => {
    navigate('/checkOut');
    setSpinner(false)
  };

  const handleQuantityChange = (productId, newQuantity) => {
    setProductQuantities((prevQuantities) => ({
      ...prevQuantities,
      [productId]: newQuantity,
    }));
  };

  const increaseQuantity = async (product_slug) => {
    setSpinnerQty(true)
    const token = localStorage.getItem('authToken');
    // console.log("Token: " + token)
    try {
      const response = await fetch(`${API_BASE_URL}/addToCart/${product_slug}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ quantity: 1 }), // You can adjust the payload as needed
      });

      if (response.ok) {
        fetchData();
        setSpinnerQty(false)
        return;
      } else {
        setSpinnerQty(false)
        // Handle error if needed
        // console.error('Failed to increase quantity:', response.statusText);
      }
    } catch (error) {
      setSpinnerQty(false)
      return
    }
  };

  const decreaseQuantity = async (product_slug) => {
    setSpinnerQty(true)
    const token = localStorage.getItem('authToken');
    // console.log("Token: " + token)
    try {
      const response = await fetch(`${API_BASE_URL}/addToCart/${product_slug}`, {
        method: 'POST', // or 'POST' depending on your API
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ quantity: -1 }), // You can adjust the payload as needed
      });

      if (response.ok) {
        fetchData();
        setSpinnerQty(false)
        return;
      } else {
        setSpinnerQty(false)
        // Handle error if needed
        // console.error('Failed to decrease quantity:', response.statusText);
        return;
      }
    } catch (error) {
      setSpinnerQty(false)
      return;
    }
  };

  const cartRef = useRef(null);
  useEffect(() => {
    if (window.location.search.includes('Header')) {
      if (cartRef.current) {
        cartRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <div id='CART' ref={cartRef}>
    {/* <Banner title="Cart" /> */}
      {
        spinner ? (
          <Spinner />
        ) : (
          userStatus === 200 ? (
            <div className='Cartcontainer'>
            <ToastContainer />
              <h1>What's in my Cart?</h1>
              {cart.map((items) => (
                <div key={items.id} className='ContainerForOneItem'>
                  <img src={items.image || seller} alt='' className='cartItem' />
                  <div className='contentOfcart'>
                    <h3>{items.slug}</h3>
                    <p>{items.description}</p>
                  </div>
                  {
                    spinnerQty ? (
                      <Spinner/>
                    ) : (
                      <Quantity
                        quantity={items.quantity}
                        onIncrease={() => increaseQuantity(items.slug)}
                        onDecrease={() => decreaseQuantity(items.slug)}
                    />
                    )
                  }
                <p className='TotalPrice11'>Price: ${(parseFloat(items.unit_price.replace(/[^\d.]/g, '')) * items.quantity).toFixed(2)}</p>
                { spinner? <Spinner/> : <FontAwesomeIcon icon={faTrash} style={{ color: '#F6A7C6', width: '25px', height: '25px' }} className='trash' onClick={(e) => removeProduct(e,items.id)} />}
                </div>
              ))}
              <div className='btnPlusPriceCart'>
                {cart.length > 0 ? (
                  <div className='totalcartcheckout'>
                    <p className='TotalPrice1'>Total Price: {totalPrice}</p>
                    <button className='cartbtn' onClick={handleOrder}>
                    {spinner ? 'Loading...' : 'Order Now'}
                    </button>
                  </div>
                    ) : (
                      <div className='ButtonPlusTotal'>
                        <p>No Items in Your Cart Items</p>
                      </div>
                    )}
              </div>
          </div>
          ) : (
            <div className='allwishlist'>
                  <h1>You Should to Sign In To Show Your cart</h1>
                  <div>
                      {<Login />}
                  </div>
              </div>
          )
        )
      }
    </div>
  );
};

export default Cart;
