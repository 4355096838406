import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import './Brands.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import SearchBar from '../../components/SearchBar/SearchBar';
import Product from '../../components/Product/Product';
import axios from 'axios';
import { API_BASE_URL } from '../../config.js';
import pet3 from './../../images/Best Seller/Vectors.png';
import PageNumber from '../../components/PageNumber/PageNumber.js';
import Spinner from '../../components/Spinner/Spinner.js';
import { useLocation, useNavigate } from 'react-router-dom';

const Brands = () => {
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [brandsProducts, setBrandsProducts] = useState([]);
  const [brands, setBrands] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [inputValue, setInputValue] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [spinner, setSpinner] = useState(true);
  const [spinner2, setSpinner2] = useState(true);
  const location = useLocation();
  const navigate = useNavigate()


  const fetchBrands = async () => {
    setSpinner(true);
    const response = await axios.get(`${API_BASE_URL}/brands`);
    setBrands(response.data.data);
    setSpinner2(false);
    return
  }

  useEffect(() => {
    fetchBrands();
  }, []);


  const fetchProducts = async () => {
    try {
      let endpoint;
      if (selectedBrand) {
        if (searchText) {
          if (orderBy) {
            endpoint = `${API_BASE_URL}/products?brand=${selectedBrand}&type=not_in_point_system&search_text=${searchText}&filter=${orderBy}&per_page=15&page=${currentPage}`;
          } else {
            console.log('slectedBrand+Search')

            endpoint = `${API_BASE_URL}/products?brand=${selectedBrand}&type=not_in_point_system&search_text=${searchText}&per_page=15&page=${currentPage}`;
          }
        } else if (orderBy) {
          endpoint = `${API_BASE_URL}/products?brand=${selectedBrand}&type=not_in_point_system&per_page=15&page=${currentPage}&filter=${orderBy}`;
        } else {
          endpoint = `${API_BASE_URL}/products?brand=${selectedBrand}&type=not_in_point_system&per_page=15&page=${currentPage}`;
        }
      } else {
        if (searchText) {
          if (orderBy) {
            endpoint = `${API_BASE_URL}/products?type=not_in_point_system&search_text=${searchText}&filter=${orderBy}&per_page=15&page=${currentPage}`;
          } else {
            endpoint = `${API_BASE_URL}/products?type=not_in_point_system&search_text=${searchText}&per_page=15&page=${currentPage}`;
          }
        } else if (orderBy) {
          endpoint = `${API_BASE_URL}/products?type=not_in_point_system&per_page=15&page=${currentPage}&filter=${orderBy}`;
        } else {
          endpoint = `${API_BASE_URL}/products?type=not_in_point_system&per_page=15&page=${currentPage}`;
        }
      }
      setSpinner(true)
      const response = await axios.get(endpoint);
      setBrandsProducts(response.data.data);
      setPageCount(response.data.meta.last_page);
      setSpinner(false)
      return;
    } catch (error) {
      return;
    }
  };


  useEffect(() => {
    fetchProducts();
  }, [selectedBrand, currentPage, searchText, orderBy ]);


  const handleBrandClick = (slug) => {
    setSelectedBrand(slug.slug);
  };


  // const HandleSearchData = (e) => {
  //   if (e.key === 'Enter') {
  //     // setInputValue(e.target.value);
  //     setSearchText(inputValue)
  //     navigate(`/product?search_text=${inputValue}`);

  //   }
  // };

  const HandleFilterData = (e) => {
    setOrderBy(e.target.value);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  
const HandleSearchData = (e) => {
    setSpinner(true)
    if (e.key === 'Enter' || e.key === 'Next') {
      navigate(`/Brands?search_text=${inputValue}`);
      setSearchText(inputValue);
      setSpinner(false)
    }
  };

  const clearsearch = () => {
    setSpinner(true)
    setInputValue('');
    setSearchText('')
    setSpinner(false)
  }
  
  const handleInputChange = (e) => {
    setInputValue(e.target.value)
  }

  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const brandRef = useRef(null);
  useEffect(() => {
    if (window.location.search.includes('Header')) {
      if (brandRef.current) {
        brandRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
    return
  }, []);

  return (
    <div id='BRANDS' ref={brandRef}>
      <div className="bestbrand" id='BRAND'>
        <Slider {...settings}>
          {brands && brands.map((brand) => (
            <div key={brand.id} className="bestbrandItem" >
              <img src={brand.image} alt={brand.name} className="shopbybrand2" onClick={() => handleBrandClick(brand)} />
              <div className="ContainerOfdBrand">
                <button onClick={() => handleBrandClick(brand)}>{brand.name}</button>
              </div>
            </div>
          ))}
        </Slider>
      </div>

      <div className='allcontainerOfproducts'>
        <SearchBar
            HandleSearchData={HandleSearchData}
            HandleFilterData={HandleFilterData}
            handleInputChange={handleInputChange}
            clearSearch={clearsearch}
            searchText={inputValue}
            pageType='Holidays'
          />
        <div className='catPlusPro'>
          {spinner ? (
            <div className='spinnerInCat'>
              <Spinner />
            </div>
          )
            :
            (<Product products={brandsProducts} pageType='Holidays' />)}
        </div>
        <div><img src={pet3} alt="" className="rr" /></div>
      </div>
      <PageNumber
        onPageChange={handlePageChange}
        pageCount={pageCount}
      />
    </div>
  );
};

export default Brands;