import { useEffect } from 'react';
import pets from '../../images/Header/pawprint (3).png'
import { useQuery } from 'react-query';

export default function ScrollTop() {
    useEffect(() => {
        scrollToTop()
    }, []);
    const scrollToTop = async () => {
        try {
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
        } catch (error) {
          throw new Error('Error scrolling to top');
        }
      };
    
    // const { isLoading, isError } = useQuery('scrollToTop', scrollToTop, {
    //     enabled: false,
    //   });
    return (
        <div>
            <div style={{ height: '0rem' }} />
            <button
                onClick={() => {
                    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
                }}
                style={{
                    position: 'fixed',
                    padding: '1rem 2rem',
                    fontSize: '20px',
                    bottom: '20px',
                    right: '17px',
                    backgroundColor: '#003440',
                    color: '#fff',
                    borderRadius: '50%',
                    textAlign: 'center',
                    width: '60px',
                    height: '60px',
                    zIndex: 1000,

                }}
            >
                <img
                    src={pets}
                    style={{
                        position: 'absolute',
                        top: '13px',
                        right: '15px',
                        cursor: 'pointer',
                    }}
                ></img>
            </button>
        </div>
    );
}
