import React, { useEffect, useRef, useState } from 'react'
import FormInput from '../Login/Inputform';
import './registration.css'
import gifImg from './../../images/Banner/Sign.gif'
import { Link, useNavigate } from 'react-router-dom';
import Login from '../Login/Login';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_BASE_URL } from '../../config.js';

const Registration = () => {
  const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
  const [isChecked, setChecked] = useState(false);
  const registrationRef = useRef(null);
  const [spinner, setSpinner] = useState(false);



  const [values, setValues] = useState({
    name: "",
    email: "",
    password: "",
    password_confirmation: "",
    agree: "true"
  });
  const navigate = useNavigate();


  const handleCheckboxChange = () => {
    setChecked(!isChecked);
  };

  const toggleLoginPopup = () => {
    setIsLoginPopupOpen(!isLoginPopupOpen);
  };



  const handleRegistration = async () => {
    try {
      setSpinner(true);
  
      const response = await fetch(`${API_BASE_URL}/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
        redirect: 'manual',
      });
  
      if (response.status === 201) {
        toast.success('Registration successful');
        setIsLoginPopupOpen(!isLoginPopupOpen);
      } 
      else {
          toast.error('Email already taken');
      }
    } catch (error) {
      toast.error(`Error during registration: ${error.message}`);
    } finally {
      setSpinner(false);
    }
  };

  const handleNavToTerm = () => {
    navigate('/PrivacyPolicies')
  };



  const inputs = [
    {
      id: 1,
      name: "name",
      type: "text",
      placeholder: "Username",
      errorMessage:
        "Username should be 3-16 characters and shouldn't include any special character!",
      label: "Username",
      pattern: "^[A-Za-z0-9]{3,16}$",
      required: true,
    },
    {
      id: 2,
      name: "email",
      type: "email",
      placeholder: "Email",
      errorMessage: "It should be a valid email address!",
      label: "Email",
      required: true,
    },
    {
      id: 3,
      name: "password",
      type: "password",
      placeholder: "Password",
      errorMessage:
        "Password should be 8-20 characters and include at least 1 letter, 1 number, and 1 special character!",
      label: "Password",
      pattern: '^(?=.*[0-9])(?=.*[a-zA-Z]).{8,20}$',
      required: true,
    },
    {
      id: 4,
      name: "password_confirmation",
      type: "password",
      placeholder: "Confirm Password",
      errorMessage: "Passwords don't match!",
      label: "Confirm Password",
      required: true,
    },
  ];



  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  
  useEffect(() => {
    if (window.location.search.includes('Login')) {
      if (registrationRef.current) {
        registrationRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <div className='allContainerOfRegistration' id='resgistration' ref={registrationRef}>
      {/* <div className='overlay1' style={{ opacity: overlayOpacity }}></div> */}
      <div className='petsWelcome'>
        <h1>Hi Welcome To Pets Berry</h1>
        <img src={gifImg} className='gif'></img>
      </div>
      <div className='craeteAccount'>
        <p className='craeteAccount1'>Create Account</p>
        {inputs.map((input, index) => (
          <>
            <FormInput
              className="email"
              key={index} 
              {...input}
              value={values[input.name]}
              onChange={onChange}
            />
          </>
        ))}

        <div className='checkbox'>
          <input
            type="checkbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          <label className='labelaterms'>Agree The<label className='labelaterms' onClick={handleNavToTerm}>Terms And Conditions</label></label>
        </div>
        <button className='btnregistartion' onClick={handleRegistration}>{spinner ? 'Loading...' : 'Sign Up'}</button>
        <p className='pLogin1'>Al Ready Have An Account ? <Link className='signSpan' onClick={toggleLoginPopup}>Sign In</Link></p>
        {isLoginPopupOpen && (
          <div className="login-popup">
            <Login />
            <button className="close-popup" onClick={toggleLoginPopup}>
              <FontAwesomeIcon icon={faTimes} className='close-icon'/>
            </button>
          </div>
        )}
      </div>

    </div>
  )
}

export default Registration