import React, { useState } from 'react';
import './PageNumber.css';

const PageNumber = ({ onPageChange, pageCount }) => {
  const [currentPage, setCurrentPage] = useState(1);

  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
      onPageChange(currentPage - 1);
    }
  };

  const handlePageClick = (pageNumber) => {
    setCurrentPage(pageNumber);
    onPageChange(pageNumber);
  };

  const handleNextClick = () => {
    if (currentPage < pageCount) {
      setCurrentPage((prev) => prev + 1);
      onPageChange(currentPage + 1);
    }
  };

  const renderPageNumbers = () => {
    if (pageCount === 1) {
      return null; 
    }

    const pageNumbers = [];
    const visiblePageCount = 6;
    const startPage = Math.max(1, currentPage - Math.floor(visiblePageCount / 2));
    const endPage = Math.min(pageCount, startPage + visiblePageCount - 1);

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <div
          key={i}
          className={`page-number ${i === currentPage ? 'active' : ''}`}
          onClick={() => handlePageClick(i)}
        >
          {i}
        </div>
      );
    }
    return pageNumbers;
  };

  return (
    <div className='allNumber' >
      <div className='page-container'>
        {currentPage > 1 && (
          <div className='prev' onClick={handlePrevClick}>
            Prev
          </div>
        )}
        {renderPageNumbers()}
        {currentPage < pageCount && (
          <div className='next' onClick={handleNextClick}>
            Next
          </div>
        )}
      </div>
    </div>
  );
};

export default PageNumber;
