import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './Terms.css';
import { API_BASE_URL } from '../../config.js';
import { useQuery } from 'react-query';
import Banner from '../../Section/banner/banner.js';

const Condition = () => {

  const fetchPrivacyPolicyData = async () => {
    const response = await axios.get(`${API_BASE_URL}/page/privacy-policy`);
    return response.data.data;
  };
  const { data: privacyPolicyData, status, error } = useQuery('privacyPolicy', fetchPrivacyPolicyData);


  return (
    <div className='pTerms'>
      {privacyPolicyData?.content && (
        <div
          dangerouslySetInnerHTML={{ __html: privacyPolicyData.content }}
          style={{
            fontSize: '10px',
            fontFamily: 'Arial, sans-serif',
            fontWeight: 'normal', 
            color: '#333',
          }}
        />
      )}
    </div>
  );
};

export default Condition;
