import React from 'react';
import './AppDesigne.css';
import playStore from './../../images/AppSection/google.png';
import appStore from './../../images/AppSection/google2.png';
import telephone from './../../images/AppSection/phone.png';

function AppDesigne() {

  const playStore1 = () => {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.genix.petberry';
  };

  const googleStore = () => {
    window.location.href = 'https://apps.apple.com/us/app/petberry/id6473836274?platform=iphone';
  };

  return (
    <div className='ContainerOfAppDonlwoad'>
      <div className='contentOfDescriptionOfDonloawd'>
        <h1>Download Our App</h1>
        <p>
          Pet berry is committed to providing pet owners with the best possible products and services. They are passionate about pets and believe that every pet deserves to be healthy and happy.
        </p>
        <div className='TheDonloawdimg'>
          <img src={playStore} alt='app' className='playStore' onClick={playStore1}></img>
          <img src={appStore} alt='app' className='googleStore' onClick={googleStore}></img>
        </div>
      </div>
      <div className='theBigimg'>
        <img src={telephone}></img>
      </div>
    </div>
  );
}

export default AppDesigne;
