import React, { useEffect, useState } from 'react';
import './Categories.css';
import pet3 from './../../images/Best Seller/Vectors.png';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { API_BASE_URL } from '../../config'


function Categories({ handleSubCategoryClick }) {
  const [openCategory, setOpenCategory] = useState(null);
  const [subCategories, setSubCategories] = useState([]);
  const [categories, setCategories] = useState('');
  const currentUrl = window.location.href;
  const categoryId = currentUrl.substring(currentUrl.lastIndexOf('/') + 1);


  const fetchCategories = async () => {
    const response = await axios.get(`${API_BASE_URL}/category-subgrps/${categoryId}`);
    const categoriesData = response.data.data;
    setCategories(response.data.data);
  }



  useEffect(() => {
    if (!categories) {
      fetchCategories();
    }
  }, []);




  const handleCategoryClick = async (category) => {
    try {
      const subCategoriesResponse = await axios.get(`${API_BASE_URL}/categories/${category.id}`);
      setSubCategories(subCategoriesResponse.data.data);
      setOpenCategory((prevCategory) => (prevCategory === category ? null : category));
    } catch (error) {
    }
  };

  return (
    <div className="custom-select">
      {categories && categories.map((category, index) => (
        <div key={index} className={`category ${openCategory === category ? 'open' : ''}`}>
          <div className="category-header" onClick={() => handleCategoryClick(category)}>
            {category.name}
            <FontAwesomeIcon icon={openCategory === category ? faCaretUp : faCaretDown} className="caret-icon" />
          </div>

          {openCategory === category && (
            <div className="options-container">
              {subCategories.map((subCategory, index) => (
                <div key={index} className="option" onClick={() => handleSubCategoryClick(subCategory.slug)}>
                  {subCategory.name}
                </div>
              ))}
            </div>
          )}
        </div>
      ))}
      <div><img src={pet3} alt="" className="pats" /></div>
    </div>
  );
}

export default Categories;
