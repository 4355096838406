import React, { useEffect, useRef } from 'react'
import './Points.css'
import gifPoints from './../../images/Brands/Celebration.gif'
import { useState } from 'react'
import axios from 'axios'
import { API_BASE_URL } from '../../config.js';
import ProductOfSpecifiquePoint from './productOfSpecifiquePoint.js'
import { Link, useLocation } from 'react-router-dom'
import { useGlobalState } from '../../GlobalStateContext.js';
import Login from "../../User/Login/Login";
import Spinner from '../../components/Spinner/Spinner.js'
import Banner from '../../Section/banner/banner.js'



const Points = () => {
  const [point, setPoint] = useState('');
  const [userStatus, setUserStatus] = useState('');
  const [linkPoint, SetLink] = useState('')
  const [spinner, SetSpinner] = useState(true)
  const { setUrlCode } = useGlobalState();
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const promoCode = urlParams.get('promo_code');

    if (promoCode) {
      sessionStorage.setItem('userCode', promoCode);
      setUrlCode(promoCode);
      return;
    }
  }, [setUrlCode]);

  const token = localStorage.getItem('authToken');
  useEffect(() => {
    const fetchData = async () => {
      try {
        if (!token) {
          SetSpinner(false)
          return;
        }
        const response = await axios.get(`${API_BASE_URL}/points`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        SetSpinner(false)
        setUserStatus(response.status);
        setPoint(response.data.data);
        return;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          SetSpinner(false)
        } else {
          SetSpinner(false)
        }
        return;
      }
    };
    fetchData();
  }, []);


  useEffect(() => {
    const fetchTheLink = async () => {
      try {
        const token = localStorage.getItem('authToken');
        if (!token) {
          SetSpinner(false)
          return;
        }
        const response = await axios.get(`${API_BASE_URL}/dashboard`, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        SetLink(response.data.data)
        return;
      } catch (error) {
        return;
      }
    }
    fetchTheLink()
  }, []);

  const pointRef = useRef(null);
  useEffect(() => {
    if (window.location.search.includes('Header')) {
      if (pointRef.current) {
        pointRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <div id='POINT' ref={pointRef}>
      {
        spinner ? (
          <Spinner />
        ) : (
          userStatus === 200 ? (
            <div className='pd'>
              <div className='PointsContainer'>
                <div className='PointsInformation'>
                  <h1>Points</h1>
                  {Number(point?.total_points) === 0 ? (
                    <p>You Don't have Points</p>
                  ) : (
                    <p>You have <span className='numberOfPoint'>{Number(point?.total_points)}</span> points. You can use them to get special discount from them.</p>
                  )}
                  <div className='divOfLinkPoint'>
                    {linkPoint?.promo_url && <div><span className='shareYourLink'>Share Your Link : </span><Link className='LinkFromPoint'>{linkPoint.promo_url}</Link></div>}
                  </div>
                </div>
                <div className='gifImgPoint'>
                  <img src={gifPoints}></img>
                </div>
              </div>
              {Number(point?.total_points) !== 0 ? (
                <ProductOfSpecifiquePoint />
              ) : null}

            </div>
          ) : (
            <div className='allwishlist'>
              <h1>You Should to Sign In To Show Your Points</h1>
              <div>
                {<Login />}
              </div>
            </div>
          )
        )
      }
    </div>
  );
};

export default Points;
