import React, { useEffect, useState } from 'react';
import Header from './header';
import './header.css';
import Slider from 'react-slick';
import LoadingComponent from '../Loading/Loading.js';
import { API_BASE_URL } from '../../config.js';
import axios from 'axios';
import { useLocation } from 'react-router-dom';
import Banner from '../../Section/banner/banner.js';

const HeaderBanner = () => {
  const [title2, setTitle2] = useState([]);
  // const [title, setTitle] = useState([]);
  const [banners, setBanners] = useState([]);
  const { pathname } = useLocation();
  const [isLoading, setIsLoading] = useState(true);

  const fetchBanners = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/banners`);
      setBanners(response.data.data);
      setTitle2(response.data.data.title);
      return
    } catch (error) {
      console.log("error fetching data", error);
      return
    } finally {
      setIsLoading(false);
      return
    }
  };


  useEffect(() => {
    if (banners.length === 0 || isLoading) {
      fetchBanners();
    }
  }, []);


  const getDefaultTitle = () => {
    if (pathname.includes("contact")) return "Contact Us";
    else if (pathname.includes('CatProducts/1')) return "Cats Products";
    else if (pathname.includes('DogProducts/2')) return "Dogs Products";
    else if (pathname.includes('Holidays')) return "Holidays";
    else if (pathname.includes("Wishlist")) return "Wishlist";
    else if (pathname.includes('/AllProduct')) return "All Products";
    else if (pathname.toLowerCase().includes('/product')) return "Products";
    else if (pathname.includes('MoreDetails')) return "Product Details";
    else if (pathname.includes('Cart')) return "Cart";
    else if (pathname.includes('award')) return "Awards";
    else if (pathname.includes('registration')) return "Registration";
    else if (pathname.includes("checkOut")) return "checkOut";
    else if (pathname.includes("/PrivacyPolicies")) return "Privacy Policies";
    else if (pathname.includes("/TermsAndCondition")) return "Terms And Conditions";
    else if (pathname.includes('Brands')) return "Brands";
    else if (pathname.includes('invoice')) return "Invoice";
    else if (pathname.includes('/PointsProductDetails/')) {
      const slug = pathname.split('/').pop();
      return `Details Points`;
    } else if (pathname.includes('/MoreDetails/')) {
      const slug = pathname.split('/').pop();
      return `More details about ${slug}`;
    } else {
      return "Page Not Found";
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div className='theBigHedaer'>
      {isLoading ? (
        <LoadingComponent />
      ) : (
        <>
          <Header />
          <Slider {...settings} className="slider-container">
            {banners.map((banner, index) => (
              <div key={index} className="banner-slide">
                <img src={banner.image} alt={banner.title} className="background-image" />
                <Banner title={pathname === '/' ? banner.title : getDefaultTitle()} />
              </div>
            ))}
          </Slider>
        </>
      )}
    </div>
  );
};

export default HeaderBanner;
