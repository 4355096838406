import React from 'react'
import Registration from './Registration/Registration'

const NewUser = () => {
  return (
    <div>
        <Registration/>
    </div>
  )
}

export default NewUser