import React from 'react'
import './section2.css'

function Section2() {
  return (
    <div className='sectionContainer'>
        <div className='opp'> <p>Brands: Build Trust,Drive loyalty and achieve success</p></div>
    </div>
  )
}

export default Section2