import React from 'react'
import './tips.css'
import tips1 from './../../images/tips/tips1.png'
import tips2 from './../../images/tips/tips2.png'

function Tips() {
  return (
    <div className='ContainerOfTips'>
      <div className='catoDogo'>
        <div className='tips1' ><img src={tips1} alt='tips' className='tips11' ></img></div>
        <div className='tips2'><img src={tips2} alt='tips' className='tips22'></img></div>
      </div>
      <div className='ContentOfTips'>
        <h1>Tips:</h1>
        <p>Increased energy levels: Dogs and cats that eat a good diet will have more energy levels and be more active. This can help them to live a happier and healthier life.</p>
        <p>Stronger teeth and bones: A good diet can help to keep dogs and cats teeth and bones strong and healthy. This can help to prevent dental problems and other health issues.</p>
      </div>

    </div>
  )
}

export default Tips