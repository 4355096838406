import React, { useContext, useEffect, useState } from 'react'
import FormInput from './Inputform';
import './Login.css'
import { Link, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useAuth } from '../AuthContext/AuthContext';
import { API_BASE_URL } from '../../config.js';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import imgGoogle from '../../images/Section2/google-symbol.png'
import MyFacebookButton from './MyFacebookButton.js';



function Login() {
  const navigate = useNavigate()
  const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(true);
  const location = useLocation();
  const [spinner, setSpinner] = useState(true)
  const [spinner2, setSpinner2] = useState(false)
  const [showCreateAccount, setShowCreateAccount] = useState(true);
  const [showToast, setShowToast] = useState(true);
  const [errorMessage, setErrorMessage] = useState('');
  const [ user, setUser ] = useState([]);
  const [ profile, setProfile ] = useState([]);
  const { login } = useAuth();


  const [values, setValues] = useState({
    email: "",
    password: "",
  });


  const inputs = [
    {
      id: 1,
      name: "email",
      type: "email",
      placeholder: "Email",
      errorMessage: "It should be a valid email address!",
      label: "Email",
      required: true,
    },
    {
      id: 2,
      name: "password",
      type: "password",
      placeholder: "Password",
      errorMessage:
        "Password Required",
      label: "Password",
      required: true,
    },

  ];
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const closeLoginPopup = () => {
    setIsLoginPopupOpen(false);
  };

  const handleLogin = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });
      setSpinner2(true)
      setSpinner(false)
      if (response.ok) {
        const token = await response.json();

        // Save the token to localStorage or sessionStorage
        localStorage.setItem('authToken', token.data.api_token);
        // setToken(token.data.api_token)
        login();
        navigate('/');

        // Reload the page
        window.location.reload();
        closeLoginPopup();
        if (location.pathname !== '/registration') {
          setShowToast(true);
          toast.success(`Login successful`);
          setSpinner2(false)

        }
        else {
          setShowToast(false);
        }

      } else {
        if (location.pathname !== '/registration') {
          setShowToast(true);
          setSpinner2(false)
          toast.error(`Login failed: ${response.statusText}`);
          setSpinner2(false)
        } else {
          setShowToast(false);
          setErrorMessage("Login failed !!");
          setSpinner2(false)
        }
      }
    } catch (error) {
      if (location.pathname !== '/registration') {
        setShowToast(true);
        setSpinner2(false)
        toast.error(`Error during login: ${error.message}`);
      } else {
        setShowToast(false);
        setErrorMessage(error.message);
      }
    }
  };
  const toggleCreateAccount = () => {
    setShowCreateAccount(!showCreateAccount);
    const loginPopup = document.getElementById('login-popup');
    if (loginPopup) {
      loginPopup.style.display = 'none';
    }
  };




  const loginGoogle = useGoogleLogin({
      onSuccess: (codeResponse) => setUser(codeResponse) ,
      onError: (error) => console.log('Login Failed:', error)
  });


  useEffect(() => {
    if (user) {
      axios
        .post(
          `${API_BASE_URL}/auth/social/google?access_token=${user.access_token}`,
          {},
          {
            headers: {
              Authorization: `Bearer ${user.access_token}`,
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
          }
        )
        .then((res) => {
          setProfile(res.data);
          // console.log(res.data.data.api_token);
          // console.log(user.access_token);
          // console.log(res.data);
          localStorage.setItem('authToken', res.data.data.api_token);
          login();
          navigate('/');
        })
        .catch((err) => console.log(err));
    }
  }, [user]);
  



  return (
    <>
    {showCreateAccount && (
      <div className='createAccount2'>
        {errorMessage && <div><p className='errorMessageForLogin'>{errorMessage}</p></div>}
        <p className='signin'>Sign In </p>
        <button onClick={() => loginGoogle() } className='google_button'><img src={imgGoogle}></img>Sign in with Google</button>
        {/* <MyFacebookButton/> */}
        {inputs.map((input) => (
          <FormInput
            key={input.id}
            {...input}
            value={values[input.name]}
            onChange={onChange}
          />
        ))}
        <button className='btnLogin' onClick={handleLogin}>{spinner2 ? 'Loading...' : 'Sign In'}</button>
        <p className='pLogin'>Not a member? <Link to='/registration?Login' className='signSpan' onClick={toggleCreateAccount}>
          Sign Up
        </Link></p>
      </div>
    )}
  </>
  )
}

export default Login