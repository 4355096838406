import React from 'react'
import './Terms.css'
import term from './../../images/Banner/Accept.gif'
import axios from 'axios';
import { API_BASE_URL } from '../../config';
import { useQuery } from 'react-query';
import Banner from '../../Section/banner/banner';

function Terms() {

  const fetchTermsAndConditionData = async () => {
    const response = await axios.get(`${API_BASE_URL}/page/terms-of-use-customer`);
    return response.data.data;
  };
  const { data: TermsAndCondition } = useQuery('termsAndConditions', fetchTermsAndConditionData);

  return (
    <>
      <div className='termcontainer'>
        <div className='pTerms'>
          {TermsAndCondition?.content && <div dangerouslySetInnerHTML={{ __html: TermsAndCondition.content }} />}
        </div>
        <div className='imgTerm'>
          <img src={term}></img>
        </div>
      </div>
    </>
  )
}

export default Terms