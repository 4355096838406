import React, { useEffect, useState } from 'react';
import './header.css';
import logo from './../../images/Header/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faShoppingCart, faUser, faHeart, faTimes, faAward, faRightFromBracket, faTruckArrowRight } from '@fortawesome/free-solid-svg-icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Login from '../../User/Login/Login';
import { useAuth } from '../../User/AuthContext/AuthContext.js';
import { API_BASE_URL } from '../../config.js';
import holiday from "../../images/Header/h.gif";
import MenuItems from './MenuItems.js';
import MenuItems2 from './MenuItems2.js';
import useCartItemCount from "../../CartItemCount.js"
import { googleLogout } from '@react-oauth/google';
import axios from 'axios';

const Header = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { isLoggedIn, logout } = useAuth();
    const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isVisible, setIsVisible] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [ profile, setProfile ] = useState([]);
    const [ holidaytitle, setHolidaytitle ] = useState([]);
    const numberOfItemsInCart = useCartItemCount();

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };
    const toggleLoginPopup = () => {
        setIsLoginPopupOpen(!isLoginPopupOpen);
    };
    const handleCartPage = (e) => {
        e.preventDefault();
        if (isLoggedIn) {
            navigate('/Cart?Header');
        } else {
            navigate('/registration')
        }
    };

    const fetchHolidaytitle = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/system_configs`);
            console.log(response.data)
            setHolidaytitle(response.data.data.holiday_title);
            return
        } catch (error) {
            console.error('Error fetching menu items:', error);
            throw error;
        }
    };

    useEffect(() => {
        fetchHolidaytitle();
      }, []);


    const handleHomeClick = () => {
        const BRANDSElement = document.getElementById('BRANDS');
        const HOLIDAYSElement = document.getElementById('HOLIDAYS')
        const CONTACTUSElement = document.getElementById('CONTACTUS')
        const INVOICEElement = document.getElementById('INVOICE')
        const CARTYElement = document.getElementById('CART')
        const POINTElement = document.getElementById('POINT')
        const WISHLISTElement = document.getElementById('WISHLIST')
        const catelement = document.getElementById('cat')
        const dogElement = document.getElementById('dog')
        const allProductsElement = document.getElementById('allProducts')

        if (BRANDSElement) {
            BRANDSElement.scrollIntoView({ behavior: 'smooth' });
        }
        else if (HOLIDAYSElement) {
            HOLIDAYSElement.scrollIntoView({ behavior: 'smooth' });
        }
        else if (CONTACTUSElement) {
            CONTACTUSElement.scrollIntoView({ behavior: 'smooth' });
        }
        else if (INVOICEElement) {
            INVOICEElement.scrollIntoView({ behavior: 'smooth' });

        }
        else if (CARTYElement) {
            CARTYElement.scrollIntoView({ behavior: 'smooth' });

        }
        else if (POINTElement) {
            POINTElement.scrollIntoView({ behavior: 'smooth' });

        }
        else if (WISHLISTElement) {
            WISHLISTElement.scrollIntoView({ behavior: 'smooth' });

        }
        else if (catelement) {
            catelement.scrollIntoView({ behavior: 'smooth' });

        }
        else if (dogElement) {
            dogElement.scrollIntoView({ behavior: 'smooth' });
        }
        else if (allProductsElement) {
            allProductsElement.scrollIntoView({ behavior: 'smooth' });
        }
    }

    const handleLogout = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${API_BASE_URL}/auth/logout`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${localStorage.getItem('authToken')}`,
                },
                body: JSON.stringify({ quantity: 1 })
            });

            if (response.ok) {
                logout();
                navigate("/")
                googleLogout();
                setProfile(null);
                localStorage.removeItem('authToken')
                window.location.reload();
            } else {
                console.log('errorr occured')
            }
            return;
        } catch (error) {
            return;
        }
    };

    const handleSearchProducts = (event) => {
        if (event.key === 'Enter') {
            navigate(`/product?search_text=${searchText}`);
        }
    };


    const handleHide = () => {
        setIsVisible(false);
    };

    return (
        <div className='allContainerOfheader'>
            {isVisible && (
                <div className='offer-line'>
                    All orders over $50 &rarr; Free Delivery
                    <button onClick={handleHide} className='close-button'>
                        X
                    </button>
                </div>
            )}
            <div className='iconss'>
                <input type='text' placeholder='Search' className='SerachInputHeader' value={searchText} onChange={(e) => setSearchText(e.target.value)} onKeyDown={handleSearchProducts}></input>
                <FontAwesomeIcon icon={faBars} className='menu' onClick={toggleMenu} />
                <Link to='/invoice?Header' className='cart1'>
                    <FontAwesomeIcon icon={faTruckArrowRight} className='track' onClick={handleHomeClick} />
                </Link>
                <Link to="/award?Header" className='cart1'>
                    <FontAwesomeIcon icon={faAward} className='cart' onClick={handleHomeClick} />
                </Link>
                <Link onClick={handleCartPage} className='cart1'>
                    <FontAwesomeIcon icon={faShoppingCart} className='cart' onClick={handleHomeClick} />
                </Link>
                <span className='numberofItems'>{numberOfItemsInCart}</span>
                <Link className='cart1' to='/Wishlist?Header'>
                    <FontAwesomeIcon icon={faHeart} className='heart' />
                </Link>
                <div>
                    {localStorage.getItem('authToken') ? (
                        <Link onClick={handleLogout} className='cart1'>
                            <FontAwesomeIcon icon={faRightFromBracket} className='user' />
                        </Link>
                    ) : (
                        <Link onClick={toggleLoginPopup} className='cart1'>
                            <FontAwesomeIcon icon={faUser} className='user' />
                        </Link>
                    )}
                </div>
            </div>
            <header className='header'>
                <div className='imgDIV'>
                    <img src={logo} alt="logo" className='logo'></img>
                </div>
                <nav className={`navbar ${isMenuOpen ? 'open' : 'close'}`} >
                    <Link to="/" className={location.pathname === "/" ? "active-link" : "default-link"} >Home</Link>
                    <MenuItems />
                    <MenuItems2 />
                    <Link to={`/Brands?search_text=${searchText}`} className={location.pathname === "/Brands" ? "active-link" : "default-link"} onClick={handleHomeClick}>Brands</Link>
                    <div className='sss'><img src={holiday} className='holiday'></img><Link to={`/Holidays?search_text=${searchText}`} className={location.pathname === "/Holidays" ? "active-link  holidayA" : "default-link"} onClick={handleHomeClick}>{holidaytitle ? holidaytitle : 'Holiday'}</Link></div>
                    <Link to="/contact?Header" className={location.pathname === "/contact" ? "active-link" : "default-link"} onClick={handleHomeClick}>Contact Us</Link>
                </nav>
            </header>
            {isLoginPopupOpen && (
                <div className="login-popup" id='login-popup'>
                    <Login />
                    <button className="close-popup" onClick={toggleLoginPopup} >
                        <FontAwesomeIcon icon={faTimes} className='close-icon' />
                    </button>
                </div>
            )}
        </div>
    );
}

export default Header;