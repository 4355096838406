import React, { useEffect, useState } from 'react'
import { API_BASE_URL } from '../../config';
import about2 from '../../images/About/about2.gif'
import './AboutPage.css'
import Banner from '../../Section/banner/banner';

const AboutPage = () => {

  const [about, setAbout] = useState('');
  const fetchAbout = () => {
    fetch(`${API_BASE_URL}/page/about-us`)
      .then(response => response.json())
      .then(data => {
        setAbout(data.data);
        return
      })
      .catch(error => console.error('Error fetching about data:', error));
  };

  useEffect(() => {
    if (!about) {
      fetchAbout()
    }
  }, []);


  return (
    <div className='ContentOfAbout'>
      {/* <Banner title="About Us" /> */}

      <div className='contentOfinfo'>
        {about?.content && <div dangerouslySetInnerHTML={{ __html: about?.content }} />}
      </div>
      <div>
        <img src={about2} className='AboutImg'></img>
      </div>
    </div>
  )
}

export default AboutPage
