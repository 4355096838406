import { useEffect, useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from './config.js';
import { useLocation } from 'react-router-dom';

const fetchCartItemCount = async (setNumberOfItems) => {
  const token = localStorage.getItem('authToken');

  // Check if the token exists
  if (!token) {
    setNumberOfItems(null);
    return;
  }

  try {
    // Make the API request with the token
    const response = await axios.get(`${API_BASE_URL}/my_cart`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    // Check if the response status is 200
    if (response.status === 200) {
      setNumberOfItems(response.data.data.total_items);
    } else {
      console.error('Error fetching cart item count. Status:', response.status);
    }
  } catch (error) {
    // Handle API request error
    console.error('Error fetching cart item count:', error);
  }
};

const useCartItemCount = () => {
  const [numberOfItems, setNumberOfItems] = useState(null);
  const token = localStorage.getItem('authToken');

  let isMounted = true; 

  const fetchData = async () => {
    if (token) {
      await fetchCartItemCount(setNumberOfItems);
    }
  };

  useEffect(() => {
    fetchData();
  }, [setNumberOfItems]);

  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  if (numberOfItems === null) {
    return '';
  }

  return numberOfItems;
};

export default useCartItemCount;
