import React, { useEffect, useState } from 'react'
import Product from '../../components/Product/Product'
import SearchBar from '../../components/SearchBar/SearchBar'
import PageNumber from '../../components/PageNumber/PageNumber'
import { API_BASE_URL } from '../../config.js';
import axios from 'axios'
import './Holidays.css'
import Spinner from '../../components/Spinner/Spinner.js'
import Banner from '../../Section/banner/banner.js'
import { useNavigate } from 'react-router-dom';


function Holidays() {
  const [holidaysProducts, setHolidaysProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [pageCount, setPageCount] = useState(1);
  const [searchText, setSearchText] = useState('');
  const [filterData, setFilterData] = useState('');
  const [spinner2, setSpinner2] = useState(true)
  const [spinner, setSpinner] = useState(true)
  const [inputValue, setInputValue] = useState('')
  const navigate = useNavigate();

  const fetchData = async () => {
    let endpoint;
    try {
      if (searchText) {
        if (filterData) {
          endpoint = `${API_BASE_URL}/products?type=holiday&search_text=${searchText}&filter=${filterData}&per_page=12&page=${currentPage}`
        }
        else {
          endpoint = `${API_BASE_URL}/products?type=holiday&search_text=${searchText}&per_page=12&page=${currentPage}`
        }
      }
      else if (filterData) {
        endpoint = `${API_BASE_URL}/products?type=holiday&filter=${filterData}&per_page=12&page=${currentPage}`
      }
      else {
        endpoint = `${API_BASE_URL}/products?type=holiday&per_page=12&page=${currentPage}`
      }
      setSpinner(false)
      const response = await axios.get(endpoint);
      setPageCount(response.data.meta.last_page);
      setSpinner2(false)
      setHolidaysProducts(response.data.data);
    } catch (error) {
      return;
    }
  };


  useEffect(() => {
    fetchData();
  }, [selectedSubCategory, searchText, filterData, currentPage]);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSubCategoryClick = (slug) => {
    setSelectedSubCategory(slug);
  };

  const HandleSearchData = (e) => {
    // console.log("hiii")
      setSpinner(true)
      if (e.key === 'Enter' || e.key === 'Next') {
        // console.log("eror", inputValue)
        navigate(`/product?search_text=${inputValue}`);
        setSearchText(inputValue);
        setSpinner(false)
      }
    };
  
    const clearsearch = () => {
      setSpinner(true)
      //fetchData()
      setInputValue(''); // Clear input value
      setSearchText('')
      setSpinner(false)
    }
    


    const handleInputChange = (e) => {
      setInputValue(e.target.value)
    }

  const HandleFilterData = (e) => {
    setFilterData(e.target.value);
  };

  return (
    <div className='HOLIDAYS'>
      <div>
        <div className='allcontainerOfproducts'>
        <SearchBar
          HandleSearchData={HandleSearchData}
          HandleFilterData={HandleFilterData}
          handleInputChange={handleInputChange}
          clearSearch={clearsearch}
          searchText={inputValue}
        />
          <div className='catPlusPro'>
            {spinner2 ? (
              <div className='spinnerInCat'>
                <Spinner />
              </div>
            ) : (
              <div className='Holidays'>
                {holidaysProducts.length ? (
                  <Product products={holidaysProducts} currentPage={currentPage} pageType='Holidays' />
                ) : (
                  <div className='NoProductFound'>
                    <p>No Products Available During Holidays !!</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <PageNumber onPageChange={handlePageChange} pageCount={pageCount} />
      </div>
    </div>
  );
}

export default Holidays