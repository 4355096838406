import React, { useEffect, useState } from 'react'
import SearchBar from '../../components/SearchBar/SearchBar'
import Categories from '../../components/categories/Categories'
import './Products.css'
import Product from '../../components/Product/Product'
import PageNumber from '../../components/PageNumber/PageNumber'
import axios from 'axios'
import { API_BASE_URL } from '../../config'
import LoadingComponent from '../../components/Loading/Loading'
import { useQuery } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import Spinner from '../../components/Spinner/Spinner'
import Banner from '../../Section/banner/banner'


const Products = () => {
  const [catProducts, setCatProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [loading, setLoading] = useState(true);
  const location = useLocation()
  const [inputValue, setInputValue] = useState('')
  const [spinner, setSpinner] = useState(true);
  const [filterData, setFilterData] = useState('');
  const [fetchingData, setFetchingData] = useState(true);
  const navigate = useNavigate()
  const url = window.location.href.split("/");



    const fetchData = async () => {
      try {
        setLoading(true);
        let endpoint;

        if (searchText) {
          // console.log("search: " + searchText)
          if (filterData) {
            endpoint = selectedSubCategory
              ? `${API_BASE_URL}/products/category/${selectedSubCategory}?search_text=${searchText}&filter=${filterData}&per_page=12&page=${currentPage}`
              : `${API_BASE_URL}/products/category-grp/cats?per_page=6&page=${currentPage}&search_text=${searchText}&filter=${filterData}`;
          }
          else {
            endpoint = selectedSubCategory
              ? `${API_BASE_URL}/products/category/${selectedSubCategory}?search_text=${searchText}&per_page=12&page=${currentPage}`
              : `${API_BASE_URL}/products/category-grp/cats?per_page=6&page=${currentPage}&search_text=${searchText}`;
          }
        }
        else if (filterData) {
          endpoint = selectedSubCategory
            ? `${API_BASE_URL}/products/category/${selectedSubCategory}&filter=${filterData}&per_page=12&page=${currentPage}`
            : `${API_BASE_URL}/products/category-grp/cats?per_page=6&page=${currentPage}&filter=${filterData}`;
        }
        else {
          if (url[5] && !url[6]) {
            endpoint = `${API_BASE_URL}/products/category-subgrp/${url[5]}?page=${currentPage}`;
          } else if (url[6]) {
            endpoint = `${API_BASE_URL}/products/category/${url[6]}?page=${currentPage}`;
          } else {
            endpoint = selectedSubCategory
              ? `${API_BASE_URL}/products/category/${selectedSubCategory}?page=${currentPage}`
              : `${API_BASE_URL}/products/category-grp/cats?per_page=6&page=${currentPage}`;
          }
        }
        // console.log("endpont: " + endpoint)
        setSpinner(false)
        const response = await axios.get(endpoint);
        setPageCount(response.data.meta.last_page);
        setCatProducts( response.data.data);
        // console.log("rrr", response.data.data)
        return
      } catch (error) {
        console.error('Error fetching data:', error);
        return
      }
      finally {
        setLoading(false);
        setFetchingData(false);
        return
      }
    };

    // const { data: catProducts ,  isLoading: spinner} = useQuery(
    //   ['catProducts',  selectedSubCategory, currentPage, searchText, filterData , location.pathname],
    //   fetchData,
    //   {
    //     onSuccess: () => setLoading(false)
    //   }
    // );
    
    useEffect(() => {
      fetchData();
    }, [ selectedSubCategory,searchText,filterData,currentPage, location.pathname]);


  const handlePageChange = (pageNumber) => {
    // console.log("!!!")
    setCurrentPage(pageNumber);
  };

  const handleSubCategoryClick = (slug) => {
    setSelectedSubCategory(slug);
  };

  const HandleSearchData = (e) => {
    // console.log("hiii")
    if (e.key === 'Enter' || e.key === 'Next') {
      // console.log("eror", inputValue)
      navigate(`/CatProducts/1?search_text=${inputValue}`);
      setSearchText(inputValue);
      setSpinner(false)
    }
    };
  
    const clearsearch = () => {
      setSpinner(true)
      //fetchData()
      setInputValue(''); // Clear input value
      setSearchText('')
      setSpinner(false)
    }


  const handleInputChange = (e) => {
    setSpinner(true)
    setInputValue(e.target.value)
    setSearchText(inputValue)
    setSpinner(false)
  }


  const HandleFilterData = (e) => {
    setFilterData(e.target.value);
  }
  return (
    <div id='cat'>
      {fetchingData ? (
        <LoadingComponent />
      ) : (
        <div className='allcontainerOfproducts'>
        <SearchBar
          HandleSearchData={HandleSearchData}
          HandleFilterData={HandleFilterData}
          handleInputChange={handleInputChange}
          clearSearch={clearsearch}
          searchText={inputValue}
        />
          <div className='catPlusPro'>
            <Categories handleSubCategoryClick={handleSubCategoryClick} />
            {spinner ? (
              <div className='spinnerInCat'>
                <Spinner />
              </div>
                ) : catProducts && catProducts.length ? (
                  <Product products={catProducts} pageType='dd'/>
                ) : (
                  <p className='ProductNotExist'>No products available !!</p>
                )}
          </div>
        </div>
      )}
      <PageNumber onPageChange={handlePageChange} pageCount={pageCount} />
    </div>
  )
}

export default Products