import React, { useEffect, useState } from 'react';
import SearchBar from '../../components/SearchBar/SearchBar';
import Categories from '../../components/categories/Categories';
import './Products.css';
import Product from '../../components/Product/Product';
import PageNumber from '../../components/PageNumber/PageNumber';
import axios from 'axios';
import { API_BASE_URL } from '../../config.js';
import { useLocation, useNavigate } from 'react-router-dom';
import Spinner from '../../components/Spinner/Spinner';
import { useQuery } from 'react-query';
import Banner from '../../Section/banner/banner.js';

const DogProduct = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [selectedSubCategory, setSelectedSubCategory] = useState(null);
  const [searchText, setSearchText] = useState(null);
  const [filterData, setFilterData] = useState('');
  const [loading, setLoading] = useState(true);
  const [fetchingData, setFetchingData] = useState(true);
  const location = useLocation()
  const [inputValue, setInputValue] = useState('')
  const [spinner, setSpinner] = useState(true);
  const [dogProducts , setdogProducts] = useState([])
  const navigate = useNavigate()
  const url = window.location.href.split("/");

  const fetchData = async () => {
    try {
      let endpoint
      if (searchText) {
        if (filterData) {
          endpoint = selectedSubCategory
            ? `${API_BASE_URL}/products/category/${selectedSubCategory}?search_text=${searchText}&filter=${filterData}&per_page=12&page=${currentPage}`
            : `${API_BASE_URL}/products/category-grp/dogs?per_page=6&page=${currentPage}&search_text=${searchText}&filter=${filterData}`;
        }
        else {
          endpoint = selectedSubCategory
            ? `${API_BASE_URL}/products/category/${selectedSubCategory}?search_text=${searchText}&per_page=12&page=${currentPage}`
            : `${API_BASE_URL}/products/category-grp/dogs?per_page=6&page=${currentPage}&search_text=${searchText}`;
        }
      } else if (filterData) {
        endpoint = selectedSubCategory
          ? `${API_BASE_URL}/products/category/${selectedSubCategory}&filter=${filterData}&per_page=12&page=${currentPage}`
          : `${API_BASE_URL}/products/category-grp/dogs?per_page=6&page=${currentPage}&filter=${filterData}`;
      }
      else {
        if (url[5] && !url[6]) {
          endpoint = `${API_BASE_URL}/products/category-subgrp/${url[5]}?page=${currentPage}`;
        } else if (url[6]) {
          endpoint = `${API_BASE_URL}/products/category/${url[6]}?page=${currentPage}`;
        } else {
          endpoint = selectedSubCategory
            ? `${API_BASE_URL}/products/category/${selectedSubCategory}?page=${currentPage}`
            : `${API_BASE_URL}/products/category-grp/dogs?per_page=6&page=${currentPage}&per_page=12&page=${currentPage}`;
        }
      }
      // console.log("rrr", endpoint)
      setSpinner(false)
      const response = await axios.get(endpoint);
      setPageCount(response.data.meta.last_page);
      setdogProducts( response.data.data);
      return
    } catch (error) {
      return;
    }
    finally {
      setLoading(false);
      return
    }

  };


  useEffect(() => {
    fetchData();
  }, [ selectedSubCategory,currentPage, searchText, filterData, location.pathname]);


  // const { data: dogProducts, isLoading: spinner } = useQuery(
  //   ['dogProducts', selectedSubCategory, currentPage, searchText, filterData, location.pathname],
  //   fetchData,
  //   {
  //     onSuccess: () => setLoading(false)
  //   }
  // ); 

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleSubCategoryClick = (slug) => {
    setSelectedSubCategory(slug);
  };

  const HandleSearchData = (e) => {
    // console.log("hiii")
    if (e.key === 'Enter' || e.key === 'Next') {
      // console.log("eror", inputValue)
      navigate(`/DogProducts/2?search_text=${inputValue}`);
      // navigate(`/DogProducts/2`);
      setSearchText(inputValue);
      setSpinner(false)
    }
    };
  
    const clearsearch = () => {
      setSpinner(true)
      setInputValue(''); // Clear input value
      setSearchText('')
      setSpinner(false)
    }
  
    const handleInputChange = (e) => {
      setSpinner(true)
      setInputValue(e.target.value)
      setSearchText(inputValue)
      setSpinner(false)    }

  const HandleFilterData = (e) => {
    setFilterData(e.target.value);
  };


  return (
    <div>

      <div id='dog'>
        <div className="allcontainerOfproducts">
        <SearchBar
          HandleSearchData={HandleSearchData}
          HandleFilterData={HandleFilterData}
          handleInputChange={handleInputChange}
          clearSearch={clearsearch}

        />
          <div className="catPlusPro">
            <Categories
              handleSubCategoryClick={handleSubCategoryClick}
            />
            {spinner ? (
              <div className='spinnerInCat'>
                <Spinner />
              </div>
            ) : dogProducts && dogProducts.length ? (
              <Product products={dogProducts} />
            ) : (
              <p className='ProductNotExist'>No products available !!</p>
            )}
          </div>
        </div>
        <PageNumber
          onPageChange={handlePageChange}
          pageCount={pageCount}
        />
      </div>
    </div>
  );
}
export default DogProduct
