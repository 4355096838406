import React, { useEffect, useState } from 'react';
import pet2 from './../../images/Categories/pet2.png';
import './ShopByBrand.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../../config.js';
import { useQuery } from 'react-query';

function ShopByBrand() {
  const navigate = useNavigate();
  const [brandss, setBrandss] = useState([]);

  const handleBrandClick = () => {
    navigate(`/Brands`);
  };

  const handelSubmit = () => {
    navigate('/Brands');
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/brands`);
      setBrandss(response.data.data);
      // localStorage.setItem('brandsData', JSON.stringify(response.data.data));
      return
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };
  


  useEffect(() => {
    // const storedData = localStorage.getItem('brandsData');
    // if (storedData) {
    //   setBrandss(JSON.parse(storedData));
    //   return
    // } else {
      fetchData();
      // return
    // }
  }, []);

  return (
    <div className="ContainerOfAllSeller">
      <div className="divider">
        <div className="divider-line"></div>
        <h1 className="CategoriesTitle">Shop By Brand</h1>
        <div className="divider-line"></div>
      </div>
      <img src={pet2} alt="" className="reverse3"></img>
      <div className="bestSellerItems">
        {brandss && brandss.slice(0, 8).map((item) => (
          <div key={item.id} className="bestSellerItem" onClick={() => handleBrandClick(item.id)}>
            <img src={item.image} className="shopbybrand" alt={item.name} />
            <div className="ContainerOfdescription">
              <p>{item.name}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="btnSeller">
        <button onClick={handelSubmit}>View All</button>
      </div>
    </div>
  );
}

export default ShopByBrand;
