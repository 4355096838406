import React, { useEffect, useState } from 'react';
import './Categories.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_BASE_URL } from '../../config.js';
import cat4 from './../../images/Categories/cat.png';
import dog from './../../images/Categories/dog.png';
import pet1 from "./../../images/Categories/paw-icon.png";
import pet2 from "./../../images/Categories/pet2.png";
import { useQuery } from 'react-query';

const Categories = () => {
  const navigate = useNavigate();
  const [categories, setCategories] = useState([]);

  const fetchCategories = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/category-grps`);
      setCategories(response.data.data);
      return
    } catch (error) {
      console.error('Error fetching categories:', error);
      return
    }
  };
  
  useEffect(() => {
      fetchCategories();
  }, []);

  const handleViewAll = (categoryType, categoryId) => {
    if (categoryType.toLowerCase() === 'cats') {
      navigate(`/CatProducts/${categoryId}`);
    } else if (categoryType.toLowerCase() === 'dogs') {
      navigate(`/DogProducts/${categoryId}`);
    }
  };
  // console.log('categories', categories)

  return (
    <div className='categoriesContainer' id="categories">
      <div className="divider">
        <div className="divider-line"></div>
        <h1 className='CategoriesTitle'>Categories</h1>
        <div className="divider-line"></div>
      </div>
      <div className='reverse'><img src={pet1} alt="" /></div>
      <div className='categoriesContent'>
        {categories && categories.map(category => (
          <div key={category.id} className='overOfContent'>
            <img
              src={category.name.toLowerCase() === 'cats' ? cat4 : dog}
              className={`animalImg ${category.name.toLowerCase() === 'cats' ? 'catImage' : 'dogImage'}`}
              alt=''
            />
            <h1 className={`titleOfNAmeCAtegories${category.name === 'CATS' ? '' : '1'}`}>
              {category.name}
            </h1>
            <div className='btnCategories'>
              <button onClick={() => handleViewAll(category.name, category.id)}>View All</button>
            </div>
          </div>
        ))}
      </div>
      <div className='reverse2'><img src={pet2} alt="" /></div>
    </div>
  );
};

export default Categories;
