import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import './SearchBar.css'
const SearchBar = ({ HandleSearchData, handleInputChange, HandleFilterData , clearSearch , pageType , searchText}) => {
  return (
    <div className={`allContainerOfSearchBar ${pageType === 'Holidays' ? 'holidaySearchStyle' : 'otherSearchStyle'}`}>
      <div className='search-container'>
        <input type='text' placeholder='Search..' className="search-input" onKeyDown={HandleSearchData} onChange={handleInputChange} value={searchText}></input>
        <FontAwesomeIcon icon={faSearch} className="search-icon" onClick={HandleSearchData} />
      </div>
      <div>
        <select id="mySelect" defaultValue="default" onChange={HandleFilterData}>
          <option value="default" disabled hidden>
            Filter
          </option>
          <option value="low_price">Lowest Price</option>
          <option value="high_price">Highest Price</option>
          <option value="newest">Newest</option>
        </select>
      </div>
    </div>
  )
}

export default SearchBar