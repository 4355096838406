import React, { useEffect, useState } from 'react'
import seller from "./../../images/Best Seller/seller.png";
import './Product.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import PageNumber from '../PageNumber/PageNumber';
import axios from 'axios';
import { API_BASE_URL } from '../../config.js';
import { useNavigate, useLocation } from 'react-router-dom';
import SearchBar from '../../components/SearchBar/SearchBar';
import { toast } from 'react-toastify';
import Spinner from '../../components/Spinner/Spinner.js';
import 'react-toastify/dist/ReactToastify.css';
import { useQuery } from 'react-query';

const AllProduct = ({ products, pageType }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(1);
  const [showToast, setShowToast] = useState(true);
  const [heartColors, setHeartColors] = useState({});
  const navigate = useNavigate();
  const location = useLocation()
  const [searchText, setSearchText] = useState('');
  const [orderBy, setOrderBy] = useState('');
  const [spinner, setSpinner] = useState(true);
  const [spinner2, setSpinner2] = useState(true);
  const [AllProduct, setAllProduct] = useState([]);
  const [inputValue, setInputValue] = useState('')
  

  const parameter = new URLSearchParams(useLocation().search).get("search_text");
  let response;
  const fetchData = async () => {
    // console.log("fethc data called")
    if (!searchText) {
      setSearchText(parameter);
    }
    try {
      if (searchText) {
        if (orderBy) {
          response = await axios.get(`${API_BASE_URL}/products?per_page=15&page=${currentPage}&search_text=${searchText}&filter=${orderBy}`);
        } else {
          response = await axios.get(`${API_BASE_URL}/products?per_page=15&page=${currentPage}&search_text=${searchText}`);
        }
      } else if (orderBy) {
        response = await axios.get(`${API_BASE_URL}/products?per_page=15&page=${currentPage}&filter=${orderBy}`);
      } else {
        response = await axios.get(`${API_BASE_URL}/products?per_page=15&page=${currentPage}`);
      }
      setPageCount(response.data.meta.last_page);
      setAllProduct(response.data.data);
      setSpinner(false)
      // return response.data.data;
      return
    } catch (error) {
      return;
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchText,orderBy,currentPage]);

  const HandleSearchData = (e) => {
  // console.log("hiii")
    setSpinner(true)
    if (e.key === 'Enter' || e.key === 'Next') {
      // console.log("eror", inputValue)
      navigate(`/product?search_text=${inputValue}`);
      setSearchText(inputValue);
      setSpinner(false)
    }
  };

  const clearsearch = () => {
    setSpinner(true)
    //fetchData()
    setInputValue(''); // Clear input value
    setSearchText('')
    setSpinner(false)
  }
  
  
  const handleInputChange = (e) => {
    setSpinner(true)
    setInputValue(e.target.value)
    setSearchText(inputValue)
    setSpinner(false)
  }
  const HandleFilterData = (e) => {
  
    setOrderBy(e.target.value)
  };


  const handlePageChange = (pageNumber) => {

    setCurrentPage(pageNumber);
  };


  const handleClickProduct = (e, slug, isOutOfStock) => {
    if (!isOutOfStock) {
      navigate(`/MoreDetails/${slug}#MoreDetails`);
    }
    e.preventDefault();
    return;
  };

  const handleAddToCart = async (e, slug) => {
    e.preventDefault();
    // window.fbq('track', 'AddToCart');
    const data = {
      quantity: 1,
    };

    try {
      const token = localStorage.getItem('authToken');
      if (!token) {
        setShowToast(true);
        toast.error('Please log in to add items to your cart.');
        return;
      }
      const response = await axios.post(`${API_BASE_URL}/addToCart/${slug}`, data, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.data.message) {
        setShowToast(true);
        toast.success(response.data.message);
        // eslint-disable-next-line no-undef
        fbq('track', 'AddToCart')
      } else {
        setShowToast(true);
        toast.error('An error occurred. Please try again.');
      }
    } catch (error) {
    }
  };


  const handleWishlistItem = async (e, slug) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('authToken');
      const response = await axios.get(`${API_BASE_URL}/wishlist/${slug}/add`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.data.message) {
        setShowToast(true);
        toast.success(response.data.message);
        setHeartColors((prevColors) => ({ ...prevColors, [slug]: '#f6a7c6' }));
      } else {
        setShowToast(true);
        toast.error(response.data);
      }
    } catch (error) {
      setShowToast(true);
      toast.error('An error occurred. Please try again.');
    }
  };
  return (
    <>
      <div className='ContainerOfcat' id='allProduct'>
        <SearchBar
          HandleSearchData={HandleSearchData}
          HandleFilterData={HandleFilterData}
          searchText={inputValue}
          handleInputChange={handleInputChange}
          clearSearch={clearsearch}
          pageType='Holidays'
        />
      { spinner ? <Spinner/> 
      : 
      <div className={`ContainerOfcat2 ${pageType === 'Holidays' ? 'otherPageStyle' : 'Productitems'}`}>
            {AllProduct && AllProduct.map(item => (
              <div
                key={item.id}
                className="bestSellerItem"
              >
                <div className="divCacher">
                  {item.stock == false && (
                    <h2 className='stock_status'>{item.stock_status}</h2>
                  )}
                  {item.image ? (
                    <img src={item.image} alt={item.name} className={`imgseller ${item.stock == false ? 'outOfStock' : ''}`} onClick={(e) => { handleClickProduct(e, item.slug, item.stock == false) }} />
                  ) : (
                    <img src={seller} alt={item.name} className={`imgseller ${item.stock == false ? 'outOfStock' : ''}`} />
                  )}
                  <div className={`overlay${item.stock == false ? 'overlayOutOfStock' : ''}`}>
                    <FontAwesomeIcon icon={faHeart} className='icon12' onClick={(e) => handleWishlistItem(e, item.slug)} style={{ color: heartColors[item.slug] || 'white' }} />
                    <FontAwesomeIcon icon={faShoppingCart} className='icon12' onClick={(e) => handleAddToCart(e, item.slug)} />
                  </div>
                </div>
                <div className='ContainerOfdescriptionAndPrice' onClick={(e) => { handleClickProduct(e, item.slug, item.stock == false) }} >
                  <h2 onClick={(e) => { handleClickProduct(e, item.slug, item.stock == false) }}>{item.name}</h2>
                  {item.has_offer === true ? (
                    <div className='priceContainer'>
                      <p className='regularPricee'>{parseFloat(item.price.split('.')[1]) === 0 ? parseFloat(item.price).toFixed(0) :  parseFloat(item.price).toFixed(2)} $</p>
                      <p className='offerPrice'>{parseFloat(item.raw_price.split('.')[1]) === 0 ? parseFloat(item.raw_price).toFixed(0) :  parseFloat(item.raw_price).toFixed(2)}$</p>
                    </div>
                  ) : (
                    <p className='regularPrice'>{parseFloat(item.price.split('.')[1]) === 0 ? parseFloat(item.price).toFixed(0) :  parseFloat(item.price).toFixed(2)} $</p>
                    )}
                </div>
              </div>
            ))}
          </div>
          }
        <PageNumber
          onPageChange={handlePageChange}
          pageCount={pageCount}
        />
      </div>
    </>
  );
};

export default AllProduct;
