import { React, useRef, useEffect } from 'react'
import Categories from '../../components/categories/Categories'
import './Products.css'
import AllProduct from '../../components/Product/AllProduct'
import Banner from '../../Section/banner/banner'


const AllProductp = () => {
  const allProductsRef = useRef(null);
  useEffect(() => {
    const hash = window.location.hash;
    if (hash) {
      const targetId = hash.substring(1);
      const targetElement = document.getElementById(targetId);
      if (targetElement) {
        targetElement.scrollIntoView({ behavior: 'smooth' });
      }
    }
    return
  }, []);

  return (
      <div className='allcontainerOfproducts' id='allProducts' ref={allProductsRef}>
        <AllProduct pageType='Holidays'/>
      </div>
  )
}

export default AllProductp