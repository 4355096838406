import React, { useState } from 'react'
import './Quantity.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';

const Quantity = ({ quantity, onIncrease, onDecrease }) => {
    const increaseQuantity = () => {
        onIncrease();
    };
    const decreaseQuantity = () => {
        if (quantity > 1) {
            onDecrease();
        }
    };
    return (
        <div className='QuantityOfCard'>
            <div className='QuantityBtn'>
                <button onClick={decreaseQuantity} className='plus'><FontAwesomeIcon icon={faMinus} /></button>
                <p>{quantity}</p>
                <button onClick={increaseQuantity} className='minus'><FontAwesomeIcon icon={faPlus} /></button>
            </div>
        </div>
    )
}

export default Quantity