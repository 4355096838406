import React, { useEffect, useState } from 'react'
import playStore from './../../images/AppSection/google.png'
import appStore from './../../images/AppSection/google2.png'
import './footer.css'
import petsF from '../../images/Header/pawprint.png'
import logo from './../../images/Header/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faTiktok, faInstagram, faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { Link, useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios';
import swal from 'sweetalert';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_BASE_URL } from '../../config.js';
import { Link as RouterLink } from 'react-router-dom';


const Footer = () => {

  const [contact, setContact] = useState([]);
  const [about, setAbout] = useState([]);

  const fetchData = async () => {
    try {
      const contactResponse = await axios.get(`${API_BASE_URL}/page/contact-us`);
      const aboutResponse = await axios.get(`${API_BASE_URL}/page/about-us`);
      setContact(contactResponse.data.data);
      setAbout(aboutResponse.data.data);
      localStorage.setItem('contactData', JSON.stringify(contactResponse.data.data));
      localStorage.setItem('aboutData', JSON.stringify(aboutResponse.data.data));
      return
    } catch (error) {
      console.error('Error fetching data:', error);
      return
    }
  };


  const [values, setValues] = useState({
    email: "",
    message: "",
  });



  const playStore1 = () => {
    window.location.href = 'https://play.google.com/store/apps/details?id=com.genix.petberry';
  };
  const googleStore = () => {
    window.location.href = 'https://apps.apple.com/us/app/petberry/id6473836274?platform=iphone';
  };


  useEffect(() => {
    const storedContactData = localStorage.getItem('contactData');
    const storedAboutData = localStorage.getItem('aboutData');

    if (!storedContactData || !storedAboutData) {
      fetchData();
    } else {
      setContact(JSON.parse(storedContactData));
      setAbout(JSON.parse(storedAboutData));
    }
  }, []);


  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      for (const key in values) {
        formData.append(key, values[key]);
      }

      const token = localStorage.getItem('authToken');

      const response = await fetch(`${API_BASE_URL}/contact-us`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });

      const contentType = response.headers.get('content-type');
      if (response.ok) {
        try {
          if (contentType && contentType.includes('application/json')) {
            const responseData = await response.json();
            swal({
              title: 'Success!',
              text: 'Message sent successfully',
              icon: 'success',
              button: 'OK',
              closeOnClickOutside: false,
            });
          } else {
            swal({
              title: 'Error!',
              text: 'Failed to send message. Please try again.',
              icon: 'error',
              button: 'OK',
              closeOnClickOutside: false,
            });
          }
        } catch (jsonError) {
        }
      } else {

        try {
          if (contentType && contentType.includes('application/json')) {
            const errorData = await response.json();
            swal({
              title: 'Error!',
              text: 'Failed to send message',
              icon: 'error',
              button: 'OK',
              closeOnClickOutside: false,
            });
          } else {
            swal({
              title: 'Error!',
              text: 'Failed to send message. Please try again.',
              icon: 'error',
              button: 'OK',
              closeOnClickOutside: false,
            });
          }
        } catch (jsonError) {
          swal({
            title: 'Error!',
            text: 'Failed to send message. Please try again.',
            icon: 'error',
            button: 'OK',
            closeOnClickOutside: false,
          });
        }
      }
    } catch (error) {
    }
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  return (
    <div className='ContainerOffooter'>

      <div className='ContainerofAbout'>
        <img src={logo} alt='logo' className='logo2'></img>
        <>
          {about?.content && <div dangerouslySetInnerHTML={{ __html: about.content.split("From basic food")[0] }}></div>}

        </>
        <div className='ContainerOfEmail'>
          <div className='InputEmail'>
          </div>
          <div className='socialMedia'>
            <Link className='icon' to='https://www.facebook.com/profile.php?id=61553350051564'><FontAwesomeIcon icon={faFacebook} className='icons' /></Link>
            <Link className='icon' to='https://www.tiktok.com/@pet.berry_'><FontAwesomeIcon icon={faTiktok} className='icons' /></Link>
            <Link className='icon' to='https://www.instagram.com/pet.berry_'><FontAwesomeIcon icon={faInstagram} className='icons' /></Link>
            <Link className='icon' to='https://wa.me/96178807902'><FontAwesomeIcon icon={faWhatsapp} className='icons' /></Link>
          </div>
        </div>
      </div>

      <div className='ContainerOfShopFooter'>
        <h2>Main Heading</h2>
        <ul>
          <li><img src={petsF}></img><Link to="/">Home</Link></li>
          <li><img src={petsF}></img><Link to={`/CatProducts/1`}>Shop By Cats</Link></li>
          <li><img src={petsF}></img><Link to={`/DogProducts/2`}>Shop By Dogs</Link></li>
          <li><img src={petsF}></img><Link to="/AllProduct">All Product</Link></li>
          <li><img src={petsF}></img><Link to="/contact">Contact Us</Link></li>
          <li><img src={petsF}></img><Link to="/about">About Us</Link></li>
          <li><img src={petsF}></img><Link to="/Holidays">Holidays</Link></li>
          <li><img src={petsF}></img><Link to="/TermsAndCondition">Terms And Conditions</Link></li>
          <li><img src={petsF}></img><Link to="/PrivacyPolicies">Privacy Policy</Link></li>
        </ul>
      </div>


      <div className='continerOfcontactFooter'>
        {contact?.title && <h2>{contact.title}</h2>}
        <input
          type='email'
          placeholder='Your Email'
          name='email'
          value={values.email}
          onChange={onChange}
        >
        </input>
        <textarea
          placeholder='Your Message'
          name='message'
          value={values.message}
          onChange={onChange}>
        </textarea>
        <button onClick={(e) => handleSubmit(e)}>send</button>
        <div className='appdonFooter'>
          <h2>Download Our App</h2>
          <div className='imgDonwload'>
            <img src={playStore} alt='app' className='playStore' onClick={playStore1}></img>
            <img src={appStore} alt='app' className='googleStore' onClick={googleStore}></img>
          </div>
        </div>
      </div>
      <ToastContainer position="bottom-right" autoClose={4000} hideProgressBar={false} />
    </div>
  )
}

export default Footer
