import React, { useEffect, useState } from 'react';
import 'react-multi-carousel/lib/styles.css';
import './Points.css'
import axios from 'axios';
import { API_BASE_URL } from '../../config.js';
import { useNavigate } from 'react-router-dom';
import seller from './../../images/Best Seller/seller.png';
import Slider from 'react-slick';


const ProductOfSpecifiquePoint = () => {
  const [products, setProducts] = useState([]);
  const navigate = useNavigate()

  const fetchProducts = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/products?type=in_point_system`);
      setProducts(response.data.data);
      return
    } catch (error) {
      console.error('Error fetching product data:', error);
      return
    }
  };


  useEffect(() => {
    if (!products || products.length === 0) {
      fetchProducts()
    }
  }, []);

  const handleNavigate = (e, slug) => {
    e.preventDefault();
    navigate(`/PointsProductDetails/${slug}`);
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true, responsive: [
      {
        breakpoint: 1124,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }

  return (
    <div className='carouselContainer'>
      <h3>Which products are eligible for exchange using points?</h3>
      <Slider {...settings}>
        {products && products.map((item) => (
          <div key={item.id} className='point'>
            <div className='divCacher'>
              {item.stock == false && (
                <h2 className='stock_status'>{item.stock_status}</h2>
              )}
              {item.images && item.images[0] ? (
                <img src={item.images[0].path} alt={item.name} className={`imgseller ${item.stock == false ? 'outOfStock' : ''}`} onClick={(e) => handleNavigate(e, item.slug)} />
              ) : (
                <img src={seller} alt={item.name} className={`imgseller ${item.stock == false ? 'outOfStock' : ''}`} />
              )}

            </div>
            <div className='PointContainerOfdescriptionAndPrice'>
              <h2 onClick={(e) => handleNavigate(e, item.slug)}>{item.name}</h2>
              <div>
                <p className='PointPrice'>{parseFloat(item.points).toFixed(0)} P</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default ProductOfSpecifiquePoint;