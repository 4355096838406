import React, { useEffect, useRef, useState } from 'react'
import FormInput from '../../User/Login/Inputform';
import './Contactus.css'
import catGif from './../../images/Section2/Cat and dog.gif'
import axios from 'axios';
import { API_BASE_URL } from '../../config.js';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Banner from '../../Section/banner/banner.js';


const Contactus = () => {
  const [contact, setContact] = useState('');

  const [values, setValues] = useState({
    name: "",
    email: "",
    message: "",
  });

  useEffect(() => {
    const storedData = localStorage.getItem('contactData');
    if (storedData) {
      setContact(JSON.parse(storedData));
    } else {
      fetchContact();
    }
  }, []);

  const fetchContact = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/page/contact-us`);
      setContact(response.data.data);
      localStorage.setItem('contactData', JSON.stringify(response.data.data));
      return
    } catch (error) {
      return
    }
  };




  const inputs = [
    {
      id: 1,
      name: "name",
      type: "text",
      placeholder: "Username",
      errorMessage:
        "Username should be 3-16 characters and shouldn't include any special character!",
      label: "Username",
      pattern: "^[A-Za-z0-9]{3,16}$",
      required: true,
    },
    {
      id: 2,
      name: "email",
      type: "email",
      placeholder: "Email",
      errorMessage: "It should be a valid email address!",
      label: "Email",
      required: true,
    },
    {
      id: 3,
      name: "phone",
      type: "number",
      placeholder: "Phone Number",
      errorMessage: "Required ",
      required: true,
    }, {
      id: 4,
      name: "subject",
      type: "text",
      placeholder: "Subject",
      errorMessage: "Required ",
      required: true,
    }
  ];

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const formData = new FormData();
      for (const key in values) {
        formData.append(key, values[key]);
      }

      const token = localStorage.getItem('authToken');

      const response = await fetch(`${API_BASE_URL}/contact-us`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
        },
        body: formData,
      });
      if (response.ok) {
        try {
          const responseData = await response.json();
          toast.success('Message sent successfully', {
            pauseOnHover: true
          });
        } catch (jsonError) {
        }
      } else {
        try {
          const errorData = await response.json();
          toast.error('Failed to send message', {
            pauseOnHover: true
          });
        } catch (jsonError) {
          console.error('Server returned HTML error page:', await response.text());
          toast.error('Failed to send message. Please try again.', {
            pauseOnHover: true
          });
        }
      }
    } catch (error) {
    }
  };

  const contactRef = useRef(null);
  useEffect(() => {
    if (window.location.search.includes('Header')) {
      if (contactRef.current) {
        contactRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <>
    {/* <Banner title="Contact us" /> */}
      <div className='containerofcontact' id='CONTACTUS' ref={contactRef}>
        {/* <Banner title="Contact Us" /> */}

        <div className='ContactForm'>
          <h1 className='contactTitle'>{contact?.title && contact.title}</h1>
          {inputs.map((input) => (
            <FormInput
              className="ContactUsform"
              key={input.id}
              {...input}
              value={values[input.name]}
              onChange={onChange}
            />
          ))}
          <div className='contentOfTextarea'>
            <textarea
              placeholder='Message'
              className='textareaOfcontact'
              name="message"
              value={values.message}
              onChange={onChange}
            >
            </textarea>
          </div>
          <button className='contactbtn' onClick={(e) => handleSubmit(e)}>Send Message</button>
        </div>
        <div className='videoContact'>
          <img src={catGif} alt=''></img>
        </div>
        <ToastContainer position="bottom-right" autoClose={4000} hideProgressBar={false} />
      </div>
    </>
  )
}

export default Contactus
