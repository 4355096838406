import React, { useEffect, useState } from 'react';
import './checkout.css';
import Doggif from './../../images/Section2/Dog paw.gif';
import axios from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { API_BASE_URL } from '../../config';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../../components/Spinner/Spinner';
import Login from '../../User/Login/Login';


const Checkout = () => {
  const [total, setTtoal] = useState('');
  const [spinner2, setSpinner2] = useState(false);
  const [spinner, setSpinner] = useState(true);
  const [checkOutData, setCheckOutData] = useState([]);
  const [oldAddress, setOldAddress] = useState('');
  const [oldName, setOldName] = useState('');
  const [oldPhone, setOldPhone] = useState('');
  const [userStatus, setUserStatus] = useState(false);
  const location = useLocation();
  const [addressFetched, setAddressFetched] = useState(false);
  const navigate = useNavigate()
  const [totalLb, setTtoalLb] = useState('');
  const [msgData, setMsgData] = useState('');
  const [selectedDistrict, setSelectedDistrict] = useState(null);
  const [districts, setDistricts] = useState([]);
  const [formData, setFormData] = useState({
    phoneNumber: '',
    name: '',
    address: '',
    // districts:'',
  });

  const pathnameParams = location.pathname.split('/').filter(Boolean);
  let quantity;
  const fetchData = async () => {
    try {
      const token = localStorage.getItem('authToken');
      if (token) {
        setUserStatus(true)
        if (pathnameParams.length > 1) {
          var urlParams = new URLSearchParams(window.location.search);
          quantity = urlParams.get('quantity');
          const response = await fetch(`${API_BASE_URL}/orders/checkOut/${pathnameParams[1]}`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
              quantity: quantity !== null ? quantity : 1,
            }),
          });
          setSpinner(false)
          if (response.ok) {
            const responseData = await response.json();
            setMsgData(responseData.message)
            setCheckOutData(responseData.data.items);
            setTtoal(responseData.data.total);
            setTtoalLb(responseData.data.total_in_lira)
            setDistricts(responseData.data['districts'])
          } else {
            setMsgData(response.data.message)
          }
        } else {
          const response = await axios.get(`${API_BASE_URL}/my_cart`, {
            headers: {
              'Authorization': `Bearer ${token}`,
            },
          });
          setSpinner(false)
          setCheckOutData(response.data.data.items);
          // console.log(response.data.data.items)
          setTtoal(response.data.data.total);
          // console.log(response.data.data.total)
          setTtoalLb(response.data.data.lira_total);
          // console.log(response.data.data.lira_total)
          setDistricts(response.data.data.districts)
          return;
        }
      }
    } catch (error) {
      // console.error('Error fetching data:', error);
      return;
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleDistrictChange = (e) => {
    const selectedDistrictId = parseInt(e.target.value, 10);
    const district = districts.find((d) => d.id === selectedDistrictId);
    // console.log(district)
    setSelectedDistrict(district.name);
  };

  

  const fetchAddress = async () => {
    try {
      const authToken = localStorage.getItem('authToken');
      // console.log('Auth Token:', authToken);

      if (oldAddress && oldName && oldPhone) {
        // If oldAddress, oldName, and oldPhone are already set, use them
        setFormData({
          ...formData,
          address: oldAddress,
          name: oldName,
          phoneNumber: oldPhone
        });
      } else {
        const response = await axios.get(`${API_BASE_URL}/orders/same_address`, {
          headers: {
            'Authorization': `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        });

        // console.log('Full Response:', response);
        const fetchedData = response.data.data;
        // console.log('Fetched Data:', fetchedData);
        const fetchedName = fetchedData.username;
        const fetchedAddress = fetchedData.location; 
        const fetchedPhone = fetchedData.phone;


        setOldName(fetchedName);
        setOldAddress(fetchedAddress);
        setOldPhone(fetchedPhone);

        setFormData({
          ...formData,
          name: fetchedName,
          address: fetchedAddress,
          phoneNumber: fetchedPhone,
        });

      }
    } catch (error) {
      console.error('Error fetching address data:', error);
    }
  }

  useEffect(() => {
    fetchAddress();
  }, []);


  const [errors, setErrors] = useState({
    phoneNumber: '',
    name: '',
    address: '',
    districts:'',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
    // console.log(formData)

    setErrors({
      ...errors,
      [e.target.name]: '',
    });
  };

  const handleOrderNow = async () => {
    try {
      // Validation
      let hasErrors = false;
      const newErrors = { phoneNumber: '', name: '', address: '',districts:'' };
      
      if (!formData || !formData.phoneNumber.trim()) {
        newErrors.phoneNumber = 'Phone number is required';
        hasErrors = true;
       } else if (isNaN(parseInt(formData.phoneNumber.trim()))) {
        newErrors.phoneNumber = 'Invalid phone number';
        hasErrors = true;
      }
      
      if (!formData || !formData.name.trim()) {
        newErrors.name = 'Name is required';
        hasErrors = true;
      }

      if (!formData || !formData.address.trim()) {
        newErrors.address = 'Address is required';
        hasErrors = true;
      }
      
      // if (!formData || !formData.districts.trim()) {
      //   newErrors.districts = 'districts is required';
      //   hasErrors = true;
      // }

      if (hasErrors) {
        setErrors(newErrors);
      } else {
        let data;
        if (pathnameParams.length > 1) {
          data = {
            name: formData.name.trim(),
            location: formData.address.trim(),
            phone_number: formData.phoneNumber.trim(),
            quantity: new URLSearchParams(location.search).get("quantity") || 1,
            district: selectedDistrict,
          };
          // console.log('data',data)
          // console.log('data phone' , data.phone)
          
        } else {
          data = {
            name: formData.name.trim(),
            location: formData.address.trim(),
            phone_number: formData.phoneNumber.trim(),
            district: selectedDistrict,
          };
          // console.log('data2',data)
        }

        const codeFromSession = sessionStorage.getItem('userCode');

        if (codeFromSession) {
          data.code = codeFromSession;
          sessionStorage.removeItem('userCode');
        }
        // console.log(data)
        const token = localStorage.getItem('authToken');
        const endpointURL = pathnameParams[1] && pathnameParams[1].length > 0
          ? `${API_BASE_URL}/orders/${pathnameParams[1]}/buy_now`
          : `${API_BASE_URL}/orders/create`;
        setSpinner2(true)
        const response = await fetch(endpointURL, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
          body: JSON.stringify(data),
        });
        setSpinner2(true)
        if (response.status === 200) {
          const res = await response.json();
          toast.success(res.message);
          navigate('/invoice')
        } else {
          toast.error('Error in Your Order, Please Try Again');
        }
      }
    } catch (error) {
    }
  };





  return (
    <>
      {
        userStatus === true ? (
          <div className='allOfCheckOut'>
            <div className='contentOfCheckout'>
              <h1>Confirmation Order</h1>
              <div className='allProductInCheckout'>
                {
                  checkOutData.length >= 1 ? (
                    checkOutData.map((item, index) => (
                      <div key={index} className="MoreScroll">
                        <img src={item.image} alt={item.name} className="More2" />
                        <div className="ContainerOfDetails">
                          <h2>{item.name}</h2>
                          <p>{item.unit_price.split(".")[1] === '00' ? item.unit_price.split(".")[0] : item.unit_price }</p>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="MoreScroll">
                      <img src={checkOutData.image} alt={checkOutData.name} className="More2" />
                      <div className="ContainerOfDetails">
                        <h2>{checkOutData.name}</h2>
                        <p>{typeof checkOutData.unit_price === 'string' ? (checkOutData.unit_price.split('.')[1] === '00' ? checkOutData.unit_price.split('.')[0] : checkOutData.unit_price) : 'Invalid unit price'}</p>
                      </div>
                    </div>
                  )
                }
              </div>
              {spinner ? (
                <Spinner />
              ) : (
                checkOutData.length > 0 || checkOutData.id ? (
                  <>
                    <div className='allthesubone'>
                      <p className='TotalPrice'>Total Price In USD: {total?.split('.')[1] == '00'? total?.split('.')[0]: total}</p>
                      <p className='TotalPrices'>Total Price In LBP:{totalLb?.split('.')[0]} LBP</p>
                      <div className='checkoutInPut'>
                        <input
                          type="tel"
                          name="phoneNumber"
                          value={formData.phoneNumber || oldPhone }
                          onChange={handleChange}
                          placeholder="Enter your phone number"
                        />
                        {errors.phoneNumber && <p className='error-message'>{errors.phoneNumber}</p>}
                        <input
                          type="text"
                          name="name"
                          value={formData.name || oldName}
                          onChange={handleChange}
                          placeholder="Enter your name"
                        />
                        {errors.name && <p className='error-message'>{errors.name}</p>}

                        <div className='divOffSelectDistrict'>
                            <label className='selectDistrict'>Select District:</label>
                            <select onChange={handleDistrictChange} className='SelectDistrictDropDown'>
                            <option value="" disabled selected>Select a District</option>
                              {districts && districts.map((district) => {
                                const numericTotal = Number(total.replace('$', ''));
                                const deliveryFeeNote = numericTotal < 50 ? `${district.delivery_fees}$` : '';
                                return (
                                  <option key={district.id} value={district.id} className='optionInSelectCity'>
                                    {district.name} {deliveryFeeNote}
                                  </option>
                                );
                              })}
                            </select>
                        </div>
                        {errors.districts && <p className='error-message'>{errors.districts}</p>}


                        <div className="input-container">
                          <textarea
                            type="text"
                            name="address"
                            value={formData.address || oldAddress}
                            onChange={handleChange}
                            placeholder="Enter your address"
                            className="address-input"
                          />
                          <button
                            type="button"
                            onClick={fetchAddress}
                            className='save-button' 
                            >
                            Saved data
                          </button>
                        </div>


                        {errors.address && <p className='error-message'>{errors.address}</p>}

                      </div>
                      {/* {errors.address && <p className='error-message'>{errors.address}</p>} */}
                    </div>
                    <div className='btnofcheckout'>
                      <button className='checkoutbtn' onClick={handleOrderNow} disabled={spinner2}
                      >
                        {spinner2 ? 'Loading...' : 'Order Now'}
                      </button>
                    </div>
                  </>
                ) : (
                  msgData ? (
                    <p>{msgData}</p>
                  ) : (
                    <p>No Items in Your Cart</p>
                  )
                )
              )}
            </div>
            <div>
              <img src={Doggif} alt='' className='doggif'></img>
            </div>
          </div>
        ) : (
          <div className='allwishlist'>
            <h1>You Should to Sign In To Buy Items</h1>
            <div>
              {<Login />}
            </div>
          </div>
        )
      }
    </>
  );
};

export default Checkout;

