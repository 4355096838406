import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { API_BASE_URL } from '../../config';
import './MoreDetails.css';
import Slider from 'react-slick';
import { useLocation } from 'react-router-dom';
import Spinner from '../../components/Spinner/Spinner';

const RelatedProductImage = ({ onSelectProduct, selectedProductId , modelNumber}) => {
  const [otherProducts, setOtherProducts] = useState([]);
  const location = useLocation();
  const [selectedColor, setSelectedColor] = useState(null);
  const [spinner, setSpinner] = useState(true);


  useEffect(() => {
    const fetchMoreData = async () => {
      try {
        const slug = window.location.pathname.split('/').pop();
        if (slug) {
          const response = await axios.get(`${API_BASE_URL}/products/${slug}`);
          setSpinner(false);
          const colors2 = response.data.data.colors2;

          const imagesArray = colors2.map(color => ({
            id: color.code, 
            image: color.image,
            color: color.color_code, 
          }));
          // console.log(imagesArray)
          
          setOtherProducts(imagesArray);
        } else {
        }
      } catch (error) {
        // console.error('Error fetching data:', error);
        setSpinner(false)

      }
    };
    fetchMoreData();
  }, [location.pathname]);

  const getColorFromImage = (filename) => {
    const match = filename.match(/-([A-Za-z]+)\d+/);
    return match ? match[1] : null;
  };

  const handleImageClick = (product) => {
    setSelectedColor(product.color);
    onSelectProduct(product);
  };

  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
  };

  return (
    <div className='containerofproductRealeteds'>
      { spinner ? (
      <Spinner/>
      ) : (
      <Slider {...settings}>
        {otherProducts.map((product, index) => (
          <div
            key={index}
            onClick={() => handleImageClick(product)}
            className={`theproductmeni ${selectedProductId === product.id ? 'selected' : ''} ${
              modelNumber && product.id === modelNumber ? 'selected' : ''
            }`}          >
            <img src={product.image} alt={`Product ${index + 1}`} />
          </div>
        ))}
      </Slider>
      )}
    </div>
  );
};

export default RelatedProductImage;