import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './User/AuthContext/AuthContext';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { QueryClient, QueryClientProvider } from 'react-query';
import { GlobalStateProvider } from './GlobalStateContext';
import { getAuth, signInWithPopup, FacebookAuthProvider } from 'firebase/auth';

const queryClient = new QueryClient();
// Fetch the promo code from the URL
const urlParams = new URLSearchParams(window.location.search);
const promoCode = urlParams.get('promo_code');

const root = ReactDOM.createRoot(document.getElementById('root'));


// Save the promo code to session storage
if (promoCode) {
  sessionStorage.setItem('userCode', promoCode);
}

root.render(
    <GoogleOAuthProvider clientId="389373197516-bu42a3ek38meqqsbd85l32jbin48mns0.apps.googleusercontent.com">
        <AuthProvider>
            <GlobalStateProvider>
              <QueryClientProvider client={queryClient}>
                    <App />
              </QueryClientProvider>
            </GlobalStateProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();




