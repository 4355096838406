import React, { useEffect, useState } from 'react';
import seller from "./../../images/Best Seller/seller.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import './BestSeller.css';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Slider from 'react-slick';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_BASE_URL } from '../../config.js';
import { useQuery } from 'react-query';

const BestSeller = () => {
  const navigate = useNavigate();
  const [showToast, setShowToast] = useState(true);
  const [heartColors, setHeartColors] = useState({});
  const [bestSeller, setBestSeller] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/products?type=best_seller`);
      setBestSeller(response.data.data);
      // console.log("AW"+response.data.data)
      // localStorage.setItem('bestSellerData', JSON.stringify(response.data.data));
      return
    } catch (error) {
      // console.error('Error fetching data:', error);
    }
  };


  const handleAddToCart = async (e, slug) => {
    e.preventDefault();
    // window.fbq('track', 'AddToCart');
    if (isButtonDisabled) {
      return;
    }
    const data = {
      quantity: 1,
    };

    try {
      const token = localStorage.getItem('authToken');

      // Check if the user is not logged in
      if (!token) {
        setIsButtonDisabled(false);
        setShowToast(true);
        toast.error('Please log in to add items to your cart.');
        return;
      }

      const response = await axios.post(`${API_BASE_URL}/addToCart/${slug}`, data, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.data.message) {
        setShowToast(true);
        toast.success(response.data.message);
        // console.log(response.data.message);
      } else {
        // Handle other cases if needed
        setShowToast(true);
        toast.error('An error occurred. Please try again.');
        // console.error('Error adding to cart. Response data:', response.data);
      }
    } catch (error) {
      // console.error('Error adding to cart:', error.message);
    } finally {
      setTimeout(() => {
        setIsButtonDisabled(false);
      }, 5000); 
    }
  };



  const handleWishlistItem = async (e, slug) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('authToken');
      const response = await axios.get(`${API_BASE_URL}/wishlist/${slug}/add`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.data.message) {
        setShowToast(true);
        toast.success(response.data.message);

        // Update the color for the specific product
        setHeartColors((prevColors) => ({ ...prevColors, [slug]: '#f6a7c6' }));
      } else {
        // Check for specific error conditions in your response data
        if (response.data.error === 'some_error_condition') {
          setShowToast(true);
          toast.error('An error occurred. Please try again.');
          // console.error('Error adding to wishlist. Response data:', response.data);
        } else {
          // Handle other cases if needed
        }
      }
    } catch (error) {
      setShowToast(true);
      toast.error('An error occurred. Please try again.');
      // console.error('Error adding to wishlist:', error.message);
    }
  };


  useEffect(() => {
    // const storedData = localStorage.getItem('bestSellerData');
    // if (storedData) {
    //   setBestSeller(JSON.parse(storedData));
    // } else {
    //   fetchData();
    // }
    // if(!bestSeller || bestSeller.length === 0){
      fetchData()
    // }
  }, []);
  

  // const handelSubmit = () => {
  //   navigate('/BestSeller');
  // };

  // console.log('Best Seller in ho0me', bestSeller)

  const settings = {
    dots: false,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 430,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  }
  const handleButtonClick = (e, slug, isOutOfStock) => {
    if (!isOutOfStock) {
      navigate(`/MoreDetails/${slug}#MoreDetails`);
      // console.log('heyyy222222222')
    }

    e.preventDefault();
    // console.log('heyyy')
    return;
  };

  return (

    <div className='ContainerOfAllSeller23'>
      <div className="divider">
        <div className="divider-line"></div>
        <h1 className='CategoriesTitle'>Best Seller</h1>
        <div className="divider-line"></div>
      </div>
      <Slider {...settings}>
        {/* <div className='bestSellerItems'> */}
        {bestSeller && bestSeller.map((item, index) => (
          <div key={index} className='allContainerOfBestSeller'  >
            <div key={item.id} className='bestSellerItem'>
              <div className='divCacher' >
                {item.stock == false && (
                  <h2 className='stock_status'>{item.stock_status}</h2>
                )}
                <img src={item.image} alt={item.name} className={`imgseller ${item.stock == false ? 'outOfStock' : ''}`} onClick={(e) => handleButtonClick(e, item.slug, item.stock == false)}/>
                <div className={`overlay${item.stock == false ? 'overlayOutOfStock' : ''}`}>
                  <FontAwesomeIcon icon={faHeart} className='icon12' onClick={(e) => handleWishlistItem(e, item.slug)} style={{ color: heartColors[item.slug] || 'white' }} />
                  <FontAwesomeIcon icon={faShoppingCart} className='icon12' onClick={(e) => handleAddToCart(e, item.slug)} id={item.slug}/>
                </div>
              </div>
              <div className='ContainerOfdescriptionAndPrice' onClick={(e) => handleButtonClick(e, item.slug, item.stock == false)}>
                <h2>{item.name}</h2>
                {item.has_offer === true ? (
                  <div className='priceContainer'>
                    <p className='regularPricee'>{parseFloat(item.price.split('.')[1]) === 0 ? parseFloat(item.price).toFixed(0) :  parseFloat(item.price).toFixed(2)}$</p>
                    <p className='offerPrice'>{parseFloat(item.raw_price.split('.')[1]) === 0 ? parseFloat(item.raw_price).toFixed(0) :  parseFloat(item.raw_price).toFixed(2)}$</p>
                  </div>
                ) : (
                    <p className='regularPrice'>{parseFloat(item.price.split('.')[1]) === 0 ? parseFloat(item.price).toFixed(0) :  parseFloat(item.price).toFixed(2)} $</p>

                )}
              </div>
            </div>
          </div>
        ))}
        {/* </div> */}
      </Slider>
      {/* <div className='btnSeller'>
        <button onClick={handelSubmit}>View All</button>
      </div> */}
    </div>
  );
};

export default BestSeller;
