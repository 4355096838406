import React, { useState, useEffect } from 'react';
import Categories from '../Section/Categories/Categories';
import BestSeller from '../Section/BestSeller/BestSeller';
import ShopByBrand from '../Section/ShopByBrand/ShopByBrand';
import Section2 from '../Section/Section2/section2';
import AllProduct from '../Section/AllProduct/AllProduct';
import Tips from '../Section/tips/tips';
import AppDesigne from '../Section/App/AppDesigne';
import ScrollTop from '../Section/scroll/ScrollTop';
import { useIdleTimer } from 'react-idle-timer'
import { useAuth } from '../User/AuthContext/AuthContext';
import { Outlet } from 'react-router-dom';

// import './WishList.css'

const timeout = 40_000
const promptBeforeIdle = 16_000

const Home = () => {
  const [state, setState] = useState('Active')
  const [remaining, setRemaining] = useState(timeout)
  const [open, setOpen] = useState(false)
  const { logout } = useAuth();

  const onIdle = () => {
    setState('Idle')
    setOpen(false)
  }

  const onActive = () => {
    setState('Active')
    setOpen(false)
  }

  const onPrompt = () => {
    setState('Prompted')
    setOpen(true)
  }

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500
  })

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000))
    }, 500)

    return () => {
      clearInterval(interval)
    }
  })

  const handleStillHere = () => {
    activate()
  }

  const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0)
  const seconds = timeTillPrompt > 1 ? 'seconds' : 'second'


  return (
    <div className='home' id='HOME'>
      <>
        <Categories />
        <BestSeller />
        <Section2 />
        <ShopByBrand />
        <Tips />
        <AllProduct />
        <AppDesigne />
        <ScrollTop />
        <Outlet/>
      </>
    </div>
  );
};

export default Home;
