import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import axios from 'axios';
import { useQuery, useQueryClient } from 'react-query';
import { API_BASE_URL } from '../../config';


const MenuItems = () => {
    const [currentCategoryId, setCurrentCategoryId] = useState('');
    const [selectedSubCategoryId, setSelectedSubCategoryId] = useState('');
    const [isMenuVisible, setMenuVisible] = useState(false);
    const [isMenuVisible2, setMenuVisible2] = useState(false);
    const [currentSubgroup, setCurrentSubgroup] = useState(null);
    const [searchText, setSearchText] = useState('');
    const location = useLocation();
    const queryClient = useQueryClient();
    const [menuItems , setMenuItems] = useState([])

    const fetchMenuItems = async () => {
        try {
            const response = await axios.get(`${API_BASE_URL}/mainMenu`);
            setMenuItems (response.data);
            return
        } catch (error) {
            console.error('Error fetching menu items:', error);
            throw error;
        }
    };

    useEffect(() => {
        // const storedMenuItems = localStorage.getItem('menuItems');
        // if (storedMenuItems) {
        //     setMenuItems(JSON.parse(storedMenuItems));
        // } else {
            fetchMenuItems();
        // }
    }, []);


    // const [dataFetched, setDataFetched] = useState(false);

    // const { data: menuItems, isLoading, isError, error } = useQuery('menuItems', fetchMenuItems, {
    //     enabled: !dataFetched,
    // });

    // useEffect(() => {
    //     if (!dataFetched && !isLoading && !isError) {
    //         setDataFetched(true);
    //     }
    // }, [dataFetched, isLoading, isError]);

    const handleMouseEnter = (categoryId, subcategoryId, subgroup) => {
        setCurrentCategoryId(categoryId);
        setSelectedSubCategoryId(subcategoryId);
        setMenuVisible(true);
        setMenuVisible2(true);
        setCurrentSubgroup(subgroup);
    };

    const handleMouseLeave = () => {
        setMenuVisible2(false);
        setCurrentSubgroup(null);
        setMenuVisible(false);
    };


    const handleCATClick = () => {
        const catElement = document.getElementById("cat")
        if (catElement) {
            catElement.scrollIntoView({ behavior: 'smooth' });
        }
    };


    return (
        <div className='shopByCatsContainer' onMouseLeave={handleMouseLeave}>
            <div className='menuContainer' >
                <Link
                    to={`/CatProducts/1?search_text=${searchText}`}
                    className={`${location.pathname === `/CatProducts/1` ? 'active-link' : 'default-link'}`}
                    onMouseEnter={() => handleMouseEnter(1, '', '')}
                    onClick={handleCATClick}
                >
                    Shop By Cats
                </Link>
                {isMenuVisible && (
                    <div className='subSubCategoriesContainer'
                        onMouseLeave={handleMouseLeave}

                    >
                        {Array.isArray(menuItems) && menuItems.length > 0 && (
                            <ul className='menuForCats'>
                                {menuItems[0].subgroup &&
                                    Object.values(menuItems[0].subgroup).map((subgroup) => (
                                        <li
                                            key={subgroup.id}
                                            className='limenu2'
                                            onMouseEnter={() => handleMouseEnter(1, '', subgroup)}
                                        >
                                            <Link to={`/CatProducts/${currentCategoryId}/${subgroup.slug}`} className="subSubCategories">
                                                {subgroup.name}
                                            </Link>
                                            {isMenuVisible2 && currentSubgroup === subgroup && (
                                                <ul className='subSub' onMouseLeave={handleMouseLeave}>
                                                    {subgroup.categories && Object.entries(subgroup.categories).map(([slug, categoryName], index) => (
                                                        <li key={index} className='limenu'>
                                                            <Link to={`/CatProducts/${currentCategoryId}/${subgroup.slug}/${slug}`} className="subSubCategories">
                                                                {categoryName}
                                                            </Link>
                                                        </li>
                                                    ))}
                                                </ul>
                                            )}
                                        </li>
                                    ))}
                            </ul>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default MenuItems
