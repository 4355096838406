import React, { useEffect, useRef, useState } from 'react'

import './WishList.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Login from '../../User/Login/Login';
import { API_BASE_URL } from '../../config.js';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Spinner from '../../components/Spinner/Spinner.js';
import Banner from '../../Section/banner/banner.js';


const Wishlist = () => {
  const [wishlistItems, setWishlistItems] = useState([]);
  const [totalPrice, setTotalPrice] = useState([]);
  const [userStatus, setUserStatus] = useState([]);
  const [spinner, setSpinner] = useState(true);
  const navigate = useNavigate()

  // const navigate = useNavigate();

  const removeProductFromWishlist = async (id) => {
    try {
      // console.log("ID:" + id)
      const token = localStorage.getItem('authToken');
      const response = await fetch(`${API_BASE_URL}/wishlist/${id}/remove`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ quantity: 1 }),
      });

      if (response.status === 200) {
        // console.log('Product removed from wishlist');
        // setWishlistItems(wishlistItems.filter(item => item.id !== id));
        window.location.reload();
      }
      else {
        // Handle other status codes or show an error message
        // console.error('Error removing product from wishlist:', response.data.message);
      }
      return
    } catch (error) {
      // Handle network errors or other exceptions
      // console.error('Error removing product from wishlist:', error.message);
      return
    }
  };;


  const token = localStorage.getItem('authToken');
  const fetchData = async () => {
    try {
      // Retrieve the Bearer token from wherever you have stored it (e.g., local storage)
      if (!token) {
        // console.error('Authentication token is missing.');
        setSpinner(false)
        return;
      }
      const response = await axios.get(`${API_BASE_URL}/wishlist`, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setSpinner(false);
      setUserStatus(response.status)

      if (response.status === 200) {
        // console.log(token);
        setTotalPrice(response.data.meta.total)
        setWishlistItems(response.data.data);
      } else if (response.status === 401) {
        // console.log("401 Unauthorized");
      }
      return
    } catch (error) {
      // console.error('Error fetching data:', error);
      setSpinner(false);
      return
    }
  };

  useEffect(() => {
    // Function to fetch data from the Laravel API
    fetchData();

  }, []);

  // const handleNavigation = () => {
  //   navigate('/checkOut');
  // };

  const handleAddToCart = async (e, slug) => {
    e.preventDefault();
    // window.fbq('track', 'AddToCart');
    const token = localStorage.getItem('authToken');
    try {
      const data = {
        quantity: 1
      }

      const response = await fetch(`${API_BASE_URL}/addToCart/${slug}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify(data)
      });

      if (response.status === 200) {
        toast.success(`Product Added to your Cart Successfuly`);
      } else if (response.status === 409) {
        toast.error(`Item alrealy in your cart`);
      } else {
        toast.error(`Error in add to cart, Please Try Again`);
      }
    } catch (error) {
      console.error('Error adding to cart:', error);
    }
  };

  const wishlistRef = useRef(null);
  useEffect(() => {
    if (window.location.search.includes('Header')) {
      if (wishlistRef.current) {
        wishlistRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  const handleButtonClick = (e, slug, isOutOfStock) => {
    if (!isOutOfStock) {
      navigate(`/MoreDetails/${slug}#MoreDetails`);
    }
  }

  return (
    <div id='WISHLIST' ref={wishlistRef}>
      {
        spinner ? (
          <Spinner />
        ) : (
          userStatus === 200 ? (
            <div className='allwishlist'>
              <h1>What'in my Wishlist ?</h1>
              <div className='Product34'>
                {
                  wishlistItems.length === 0 ? (
                    <div className='ButtonPlusTotal'>
                      <p>No Items in Your Wishlist</p>
                    </div>
                  ) : (
                    wishlistItems.map(item => (
                      <div key={item.id} className='bestSellerItem'>
                        <div className='divCacher'>
                          <img src={item.image} alt={item.name} className='imgseller' onClick={(e) => handleButtonClick(e, item.slug, item.stock == false)}/>
                          <div className='overlay'>
                            <FontAwesomeIcon icon={faTimes} className='icon12' onClick={() => removeProductFromWishlist(item.id)} />
                            <FontAwesomeIcon icon={faShoppingCart} className='icon12' onClick={(e) => handleAddToCart(e, item.slug)} />
                          </div>
                        </div>
                        <div className='ContainerOfdescriptionAndPrice' onClick={(e) => handleButtonClick(e, item.slug, item.stock == false)}>
                          <h2>{item.name}</h2>
                          <p className='regularPrice'>{parseFloat(item.price.split('.')[1]) === 0 ? parseFloat(item.price).toFixed(0) :  parseFloat(item.price).toFixed(2)} $</p>
                        </div>
                      </div>
                    ))
                  )
                }
              </div>
            </div>
          ) : (
            <div className='allwishlist'>
              <h1>You Should to Sign In To Show Your Wishlist Items</h1>
              <div>
                {<Login />}
              </div>
            </div>
          )
        )
      }
    </div>
  )
}

export default Wishlist
