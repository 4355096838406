import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import Quantity from '../../components/Quantity/Quantity';
import './MoreDetails.css';
import { API_BASE_URL } from '../../config.js';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RelatedProductImage from './RelatedProductImage.js';
import Spinner from '../../components/Spinner/Spinner.js';
import { faHeart, faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import Banner from '../../Section/banner/banner.js';

const MoreDetails = () => {
  const [showDescription, setShowDescription] = useState(true);
  const [showIngredients, setShowIngredients] = useState(true);
  const [detailsData, setDetailsData] = useState(null);
  const [categoryName, setCategoryName] = useState('');
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [category, setCategory] = useState('');
  const [quantity, setQuantity] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();
  const [color, setColor] = useState(null);
  const [selectedSize, setSizes] = useState(null);
  const [modelNumber2, setModelNumber] = useState(null);
  const [value, setValue] = useState();
  const [showToast, setShowToast] = useState(true);
  const [heartColors, setHeartColors] = useState({});
  const [spinner, setSpinner] = useState(true);
  const [spinnerData, setSpinnerData] = useState(true);
  const [spinner2, setSpinner2] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const handleProductClick = (productDetails) => {
    // console.log(productDetails)
    setSelectedProduct(productDetails);
    setModelNumber(productDetails)
    // console.log(productDetails)
    setDetailsData(selectedProduct);
    if (productDetails && productDetails.slug) {
      const { slug } = productDetails;
      navigate(`/MoreDetails/${slug}`)
    }
  };



  const fetchData = async (productId) => {
    try {
      if (selectedProduct && typeof selectedProduct.id === 'string') {
        // console.log('hiiiiiiiii')
        const selectedProductId = selectedProduct.id;
        // console.log('', selectedProduct.id)
        const lastTwoCharacters = selectedProductId.slice(-2);
        // console.log('lastTwoCharacters',lastTwoCharacters)
        const response = await axios.get(`${API_BASE_URL}/products/${selectedProductId}`);
        setDetailsData(response.data.data);
        setColor(response.data.data.colors);
        const colorKeys = Object.keys(response.data.data.colors);
        // console.log("colorKeys", colorKeys)
        const matchingKeys = colorKeys.map((key) => key.split('_').pop());
        // console.log('1',matchingKeys)
        setSpinnerData(false)

        if (matchingKeys.includes(lastTwoCharacters)) {

          const colorsForMatchingKey = response.data.data.colors[`_${lastTwoCharacters}`];

          // console.log('data',response.data.data.colors)
          // console.log('2',colorsForMatchingKey)

          const sizeRegex = /[smxl]+/i;
        
          const sizesForMatchingKey = colorsForMatchingKey.filter(color => sizeRegex.test(color));
          // console.log('sizesForMatchingKey:', sizesForMatchingKey);

          if(sizesForMatchingKey.length > 0){
            // console.log('hello1')
            setSizes(sizesForMatchingKey);
          }
          else{
            setSizes([])
          }
        }

      } else {
        const slug = window.location.pathname.split('/').pop();
        const response = await axios.get(`${API_BASE_URL}/products/${productId || slug}`);
        setDetailsData(response.data.data);
        setSpinnerData(false)
        
        if (response.data.data.categories.length > 0 && response.data.data.categories[0].slug.length > 0 || response.data.data.categories.length > 0 && response.data.data.categories[0].id.length > 0) {
          setCategory(response.data.data.categories[0].slug || response.data.data.categories[0].id);
          setCategoryName(response.data.data.categories[0].name);
        }
        const lastTwoCharacters2 = response.data.data.model_number.slice(-2);
        const colorsForProduct = response.data.data.colors[`_${lastTwoCharacters2}`];

        const sizeRegex = /[smxl]+/i;
        const sizesForMatchingKey = colorsForProduct.filter(color => sizeRegex.test(color));
        // console.log('sizesForMatchingKey2:', sizesForMatchingKey);

        if(sizesForMatchingKey.length > 0){
          // console.log('hello2')
          setSizes(sizesForMatchingKey);
        }
        else{
          setSizes([])
        }

      } 
      // console.log("Spinner data "+spinner)
      return
    } catch (error) {
      return;
    }
  };
  useEffect(() => {
    // console.log("SSS")
    fetchData();
    // setSpinner(false)
  }, [location.pathname, selectedProduct]);

  const handleClickProduct = (e, slug) => {
    e.preventDefault();
    navigate(`/MoreDetails/${slug}#MoreDetails`);

  };

  const fetchMoreData = async () => {
    if (category.length > 0) {
      try {
        const response = await axios.get(`${API_BASE_URL}/products/category/${category}`);
        setRelatedProducts(response.data.data);
        setSpinner(false);
        return;
      } catch (error) {
        setSpinner(false)
        return;
      }
    }
  };
  useEffect(() => {
    if (!relatedProducts || relatedProducts.length === 0) {
       fetchMoreData(); 
      }
  }, [category, location.pathname]);

  const onIncrease = () => {
    setQuantity((prevQuantity) => prevQuantity + 1);
  };
  const onDecrease = () => {
    setQuantity((prevQuantity) => Math.max(prevQuantity - 1, 1));
  };

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const toggleDescription = () => {
    setShowDescription((prevShow) => !prevShow);
  };
  const toggleIngredients = () => {
    setShowIngredients((prevShow) => !prevShow);
  };

  const handleBuyNow = (e, slug, quantity) => {
    e.preventDefault();
    navigate('/checkOut/' + slug + '?quantity=' + quantity);
  }

  const handleAddToCart = async (e, slug, quantity) => {
    e.preventDefault();
    // window.fbq('track', 'AddToCart');
    const token = localStorage.getItem('authToken');
    try {
      const response = await fetch(`${API_BASE_URL}/addToCart/${slug}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({ quantity: quantity }),
      });
      setSpinner2(true)
      if (response.status === 200) {
        const responseData = await response.json();
        toast.success("item added to cart");
      }
      else if (response.status === 403) {
        toast.error(`Please Login To access This Feature`);
        setSpinner2(false)
      } else {
        toast.error(`Error in add to Cart, Please Try Again`);
      }
      setSpinner2(false)
    } catch (error) {
      toast.error(`Please Login To access This Feature ${error}`);
    }

  };

  const handleSetSize = async (selectedProduct, sizevalue) => {
    if (selectedProduct && selectedProduct.id) {
      const numericPart = selectedProduct.id.split('-')[0];
      const sizecolorpart = selectedProduct.id.split('-')[1];
      const lastTwoCharacters = sizecolorpart.slice(-2);
      const newSizeColorPart = sizevalue + lastTwoCharacters;
      const newProductId = `${numericPart}-${newSizeColorPart}`;
      selectedProduct.id = newProductId;
      setDetailsData(selectedProduct);
      fetchData();
    } else if (detailsData && detailsData.model_number) {
      const numericPart = detailsData.model_number.split('-')[0];
      const sizecolorpart = detailsData.model_number.split('-')[1];
      const lastTwoCharacters = sizecolorpart.slice(-2);
      const newSizeColorPart = sizevalue + lastTwoCharacters;
      const newProductId = `${numericPart}-${newSizeColorPart}`;
      fetchData(newProductId);
    } else {
    }
  };
  useEffect(() => {
    const productDetailsElement = document.getElementById('MoreDetails');
    if (productDetailsElement) {
      productDetailsElement.scrollIntoView({ behavior: 'smooth' });
    }
    return
  }, []);

  const handleWishlistItem = async (e, slug) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('authToken');
      const response = await axios.get(`${API_BASE_URL}/wishlist/${slug}/add`, {
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (response.data.message) {
        setShowToast(true);
        toast.success(response.data.message);
        setHeartColors((prevColors) => ({ ...prevColors, [slug]: '#f6a7c6' }));
        // setSpinner(false)
      } else {
        setShowToast(true);
        toast.error(response.data);
      }
    } catch (error) {
      setShowToast(true);
      toast.error('An error occurred. Please try again.');
    }
  };

  return (
<div>
  <div className="ALLMore" id='MoreDetails'>
    {selectedProduct ? (
      <div className="MoreLeft">
        <img src={selectedProduct?.image} alt={selectedProduct?.name} className="imgMore" />
        <RelatedProductImage onSelectProduct={handleProductClick} selectedProductId={selectedProduct.id} modelNumber={selectedProduct?.model_number} />
      </div>
    ) : (
      detailsData && (
        <div className="MoreLeft">
          <img src={detailsData.image} alt={detailsData.name} className="imgMore" />
          <RelatedProductImage
            onSelectProduct={handleProductClick}
            selectedProductId={detailsData.id}
            modelNumber={detailsData.model_number || modelNumber2}
          />
        </div>
      )
    )}
    <div className='widthOfMore'>
      {spinnerData ? (
        <Spinner />
      ) : (
        <>
        <div className="MoreTitleName">
          <h2>{selectedProduct?.name || detailsData?.name}</h2>
          <div className='priceInMoreDetails'>
            {selectedProduct?.has_offer === true || detailsData?.has_offer === true ? (
              <>
                {/* <h4>Price In US: <span className='priceInSpan'>{parseFloat(selectedProduct?.offer_price || detailsData?.offer_price).toFixed(1)} $</span></h4> */}
                <h4>Price In US: <span className='priceInSpan'>{parseFloat(selectedProduct?.offer_price.split('.')[1] === 0 || detailsData?.offer_price.split('.')[1]) === 0 ? parseFloat(selectedProduct?.offer_price || detailsData?.offer_price).toFixed(0) : parseFloat(selectedProduct?.offer_price || detailsData?.offer_price).toFixed(2) } $</span></h4>
                <h4>Price In LBP: <span className='priceInSpan'>{(selectedProduct?.lira_offer_price || detailsData?.lira_offer_price)?.split('.')[0]} LBP</span></h4>
              </>
            ) : (
              <>
                <h4>Price In US: <span className='priceInSpan'>{parseFloat(selectedProduct?.price.split('.')[1] === 0 || detailsData?.price.split('.')[1]) === 0 ? parseFloat(selectedProduct?.price || detailsData?.price).toFixed(0) :parseFloat(selectedProduct?.price || detailsData?.price).toFixed(2) } $</span></h4>
                <h4>Price In LBP: <span className='priceInSpan'>{(selectedProduct?.lira_price || detailsData?.lira_price)?.split('.')[0]} LBP</span></h4>
              </>
            )}
          </div>
        </div>
        <div className="MoreDetails">
        <div className="MoreDetails1">
          <h1>Details</h1>
        </div>
        <div onClick={toggleDescription} className='minusPlus'>
          {showDescription ? <FontAwesomeIcon icon={faMinus} className='minusPluss' /> : <FontAwesomeIcon icon={faPlus} className='minusPluss' />}
        </div>
      </div>
      {showDescription && (
        <div className="Description">
          <p dangerouslySetInnerHTML={{ __html: selectedProduct?.description || detailsData?.description }}></p>
        </div>
      )}
      <div className="MoreIngredient">
        <div className="MoreIngredient1">
          <h1>Ingredients & Analysis</h1>
        </div>
        <div onClick={toggleIngredients} className='minusPlus'>
          {showIngredients ? <FontAwesomeIcon icon={faPlus} className='minusPluss' /> : <FontAwesomeIcon icon={faMinus} className='minusPluss' />}
        </div>
      </div>
      {showIngredients && (
        <div className="Ingredients">
          <p dangerouslySetInnerHTML={{ __html: selectedProduct?.ingredients || detailsData?.ingredients }}></p>
        </div>
      )}
      <div className='BuyNowPlusQuantity'>
        <Quantity
          quantity={quantity}
          onIncrease={onIncrease}
          onDecrease={onDecrease}
        />
        <div className='SizesMore'>
          {selectedSize && selectedSize.length > 0 ? (
            selectedSize.map((sizeValue, index) => (
              <div key={index} className='sizeContrainer'>
                <button onClick={() => handleSetSize(selectedProduct, sizeValue)}>{sizeValue}</button>
              </div>
            ))
          ) : (
            <p className='pSize'>No sizes available</p>
          )}
        </div>
      </div>
      <div className='btnForAllmore'>
        <button className='ByBtn' onClick={(e) => handleBuyNow(e, selectedProduct?.slug || detailsData?.slug, quantity)}>Buy Now</button>
        <button className='addBtn' onClick={(e) => handleAddToCart(e, selectedProduct?.slug || detailsData?.slug, quantity)} disabled={spinner2}>{spinner2 ? 'Loading...' : 'Add To Cart'}</button>
      </div>
        </>
      )}

      
    </div>
  </div>
  <div className="allsliderOfMore">
    <h1>MORE PRODUCTS</h1>
    {spinner ? (
      <Spinner />
    ) : (
      <Slider {...settings}>
        {relatedProducts.map((item, index) => (
          <div
            key={item.id}
            className="bestSellerItem"
          >
            <div className="divCacher">
              {item.stock == false && (
                <h2 className='stock_status'>{item.stock_status}</h2>
              )}
              <img src={item.image} alt={item.name} className={`imgseller ${item.stock == false ? 'outOfStock' : ''}`} />
              <div className={`overlay${item.stock == false ? 'overlayOutOfStock' : ''}`}>
                <FontAwesomeIcon icon={faHeart} className='icon12' onClick={(e) => handleWishlistItem(e, item.slug)} style={{ color: heartColors[item.slug] || 'white' }} />
                <FontAwesomeIcon icon={faShoppingCart} className='icon12' onClick={(e) => handleAddToCart(e, item.slug)} />
              </div>
            </div>
            <div className='ContainerOfdescriptionAndPriceInMoreDetails' onClick={(e) => { handleClickProduct(e, item.slug, item.stock == false) }} >
              <h2 onClick={(e) => { handleClickProduct(e, item.slug, item.stock == false) }}>{item.name}</h2>
                {item.has_offer === true ? (
                  <div className='priceContainer'>
                    <p className='regularPricee'>{parseFloat(item.price.split('.')[1]) === 0 ? parseFloat(item.price).toFixed(0) :  parseFloat(item.price).toFixed(2)}$</p>
                    <p className='offerPrice'>{parseFloat(item.raw_price.split('.')[1]) === 0 ? parseFloat(item.raw_price).toFixed(0) :  parseFloat(item.raw_price).toFixed(2)}$</p>
                  </div>
                ) : (
                    <p className='regularPrice'>{parseFloat(item.price.split('.')[1]) === 0 ? parseFloat(item.price).toFixed(0) :  parseFloat(item.price).toFixed(2)} $</p>
                )}
            </div>
          </div>
        ))}
      </Slider>
    )}
  </div>
</div>
  );
};

export default MoreDetails;
